import { ReactComponent as Home } from 'src/assets/lms/icons/home.svg';
import { ReactComponent as Recruitment } from 'src/assets/lms/icons/recruitment.svg';
import { ReactComponent as Reporting } from 'src/assets/lms/icons/reporting.svg';
import { ReactComponent as Sales } from 'src/assets/lms/icons/sales.svg';
import { ReactComponent as Teams } from 'src/assets/lms/icons/teams.svg';

import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';
import { ReactComponent as Award } from 'src/assets/lms/icons/award.svg';
import { ReactComponent as Completed } from 'src/assets/lms/icons/completed.svg';
import { ReactComponent as Progress } from 'src/assets/lms/icons/progress.svg';
import { ReactComponent as Saved } from 'src/assets/lms/icons/saved.svg';

export const getMenuItems = (role) => {
  return [
    {
      title: 'Home',
      link: '/lms',
      icon: <Home />,
      children: [
        {
          title: 'In Progress',
          link: '/lms/progress',
          icon: <Progress />,
          countKey: 'inProgress',
        },
        {
          title: 'Assigned to Me',
          link: '/lms/assigned',
          icon: <Assigned />,
          countKey: 'assignedToMe',
        },
        {
          title: 'Saved',
          link: '/lms/saved',
          icon: <Saved />,
          countKey: 'saved',
        },
        // {
        //   title: 'Competition',
        //   link: '/lms/competition',
        //   icon: <Award />,
        //   countKey: 'competitions',
        // },
        {
          title: 'Completed',
          link: '/lms/completed',
          icon: <Completed />,
          countKey: 'completed',
        },
      ],
    },
    {
      title: 'Sales',
      link: '/lms/sales',
      icon: <Sales />,
    },
    {
      title: 'Recruitment',
      link: '/lms/recruitment',
      icon: <Recruitment />,
    },
    {
      title: 'Reporting',
      link: '/lms/dashboard',
      icon: <Reporting />,
    },
    ...(role !== 'standard'
      ? [
          {
            key: 'team',
            title: 'Team',
            link: '/lms/team/users',
            icon: <Teams />,
          },
        ]
      : []),
  ];
};
