import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form, Spacer, Stepper } from 'src/components/shared';
import {
  fetchTeamsLookup,
  fetchOfficesLookup,
  fetchTimezoneLookup,
} from 'src/modules/admin/api/adminApi';

import api from 'src/api';
import { userNameRegex, permissionLevels } from './config';
import { contactNameRegex } from 'src/config/index';

function UserForm({ drawerToggle, editUser, formData, total, loading, ...props }) {
  const [defaultCandidate, setDefaultCandidate] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const titleList = useMemo(() => {
    return props?.globals?.titlesList || [];
  }, [props.globals]);

  useEffect(() => {
    if (editUser && editUser?.id) {
      setDefaultCandidate(editUser);
    }
  }, [editUser]);

  const searchCandidates = async (search, lookupType, total) => {
    let res = [];
    if (lookupType === 'team') {
      res = await fetchTeamsLookup(search, total);
    } else if (lookupType === 'office') {
      res = await fetchOfficesLookup(search, total);
    } else if (lookupType === 'timezone') {
      res = await fetchTimezoneLookup(search);
    }
    return res || [];
  };

  const handleSubmit = async (values, form) => {
    setSubmitLoading(true);
    const data = { ...values };
    if (values.team.id) {
      data.team = values.team.id ? [values.team.id] : [];
    } else {
      delete data.team;
    }
    if (values.office.id) {
      data.office = values.office.id ? [values.office.id] : [];
    } else {
      delete data.office;
    }
    data.timezone = values.timezone.value || 'UGT';

    data.isAdmin = values.accessLevel === 'admin' ? true : false;
    data.acl = { accessLevel: values.accessLevel || '' };
    data.permission = values.accessLevel || '';
    delete data.accessLevel;
    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      await props.putUser(updatedRow);
      setSubmitLoading(false);
      drawerToggle({ edit: null, open: false });
    } else {
      await props.saveUser(data);
      setSubmitLoading(false);
      drawerToggle({ edit: null, open: false });
    }
  };

  return (
    <Form
      initialValues={{
        title: defaultCandidate?.title || '',
        fname: defaultCandidate.fname || '',
        lname: defaultCandidate.lname || '',
        email: defaultCandidate.email || '',
        status: defaultCandidate.status || 'active',
        jobTitle: defaultCandidate.jobTitle || '',
        timezone: defaultCandidate?.id
          ? { id: defaultCandidate?.id, value: defaultCandidate?.timezone }
          : {},
        accessLevel: defaultCandidate?.accessLevel || 'Standard',
        team: defaultCandidate?.team
          ? {
              id: defaultCandidate?.team && defaultCandidate?.team[0]?.id,
              value: defaultCandidate?.team && defaultCandidate?.team[0]?.name,
            }
          : {},
        office: defaultCandidate?.office
          ? {
              id: defaultCandidate?.office && defaultCandidate?.office[0]?.id,
              value: defaultCandidate?.office && defaultCandidate?.office[0]?.name,
            }
          : {},

        taskNotifications: defaultCandidate?.taskNotifications || false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Please enter a valid email address.')
          .test({
            message: () => 'Email already exists.',
            test: (values) => isEmailExist(values, defaultCandidate?.email),
          })
          .required('Please enter email.'),
        jobTitle: Yup.string()
          .matches(userNameRegex, 'Job title cannot contain special character.')
          .nullable(true),
        fname: Yup.string()
          // .test('fname', 'First Name cannot contain special character', contactNameRegex)
          .max(64, 'first name must be at most 64 characters')
          .required('Please enter first name.'),
        lname: Yup.string()
          // .test('lname', 'Last Name cannot contain special character', contactNameRegex)
          .max(64, 'first name must be at most 64 characters')
          .required('Please enter last name.'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        let titleLabel = props.values.title.value ? 'Title' : '';
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            {/* <Spacer x={1} y={1} />
            <Stepper steps={['Details', 'Licence']} activeStep={0} onChange={() => {}} />
            <Spacer x={1} y={1} /> */}

            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="calc(100% - 40px)"
            >
              <Box>
                <Form.Field.Select
                  options={titleList}
                  fullWidth
                  name="title"
                  label="Title"
                  placeholder="Title"
                  variant="outlined"
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="fname"
                  label="First Name"
                />
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="lname"
                  label="Last Name"
                />

                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="jobTitle"
                  label="Job Title"
                />
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="email"
                  label="Email"
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="team"
                      label="Team"
                      placeholder="Team"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'team', total);
                      }}
                      optLabel="value"
                      optValue="id"
                      multiple={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="office"
                      label="Office"
                      placeholder="Office"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'office', total);
                      }}
                      optValue="id"
                      optLabel="value"
                      multiple={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="timezone"
                      label="Timezone"
                      placeholder="Timezone"
                      variant="outlined"
                      optLabel="value"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'timezone', total);
                      }}
                      optValue="id"
                      multiple={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Select
                      fullWidth
                      options={permissionLevels}
                      variant="outlined"
                      name="accessLevel"
                      label="Permission Level"
                      placeholder="Access Level"
                      optLabel="label"
                      optValue="value"
                      showNone={false}
                    />
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={6} ml={4} sx={{ display: 'flex' }}>
                      <Form.Field.Switch rows={4} variant="outlined" name="status" label="Status" />
                      <Spacer y={7} />
                      <TitleBar tooltip="Active/Inactive" />
                    </Grid>
                  </Grid> */}
                </Grid>
              </Box>
              <Box display="flex" mt={0} justifyContent="flex-end" width="100%">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => drawerToggle({ edit: null, open: false })}
                  loading={loading.users}
                  // disabled={loading}
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading.users || submitLoading}
                  // disabled={loading}
                >
                  {!defaultCandidate.id ? 'Save' : 'Update'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

const isEmailExist = async (values, currentEmail, loading) => {
  if (values === currentEmail) return true;
  try {
    let response = await api(`/user?email=${values}`, null, 'get');
    if (response.users.length > 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

export default UserForm;
