import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import TaskIcon from '@mui/icons-material/Task';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BadgeIcon from '@mui/icons-material/Badge';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { sequenceEnrollmentStatus, countryCodes } from '../config';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Stack } from '@mui/material';

export const getSequenceStatus = (status) => {
  switch (status) {
    case 'never sequenced':
      status = 'Never been sequenced ';
      break;
    case 'never':
      status = 'Never been sequenced ';
      break;
    case 'paused':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Paused <PauseRoundedIcon />
        </Stack>
      );
      break;
    case 'notInterested':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Completed <CloseRoundedIcon />
        </Stack>
      );
      break;
    case 'interested':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Completed <DoneRoundedIcon />
        </Stack>
      );
      break;
    case 'completed':
      status = 'Completed ';
      break;
    case 'hold':
      status = 'On hold';
      break;
    case 'inProgress':
      status = 'In Progress';
      break;
    case 'replied':
      status = 'Replied';
      break;
    case 'draft':
      status = 'Draft';
      break;
    case 'failed':
      status = 'Failed';
      break;
    case 'unsubscribed':
      status = 'Unsubscribed';
      break;
    case 'error':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Error <CircleRoundedIcon />
        </Stack>
      );
      break;
    case 'bounced':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Bounced <SquareRoundedIcon />
        </Stack>
      );
      break;
  }

  return status;
};

export const getSequenceStatusColorCode = (status) => {
  let colorCode = {
    color: 'white',
    background: '#dddddd',
  };

  switch (status) {
    case 'Never Sequenced':
      colorCode.color = '#f4f4f4';
      colorCode.background = '#626262';
      break;
    case 'never':
      colorCode.color = '#f4f4f4';
      colorCode.background = '#626262';
      break;
    case 'notInterested':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'interested':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'completed':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'paused':
      colorCode.color = '#00695c';
      colorCode.background = '#e0f2f1';
      break;
    case 'hold':
      colorCode.color = '#a53cb7';
      colorCode.background = '#f3e5f6';
      break;
    case 'inProgress':
      colorCode.color = '#2e7d32';
      colorCode.background = '#e6efe6';
      break;
    case 'replied':
      colorCode.color = '#00838f';
      colorCode.background = '#dff6f9';
      break;
    case 'draft':
      colorCode.color = '#e91e63';
      colorCode.background = '#fce4ec';
      break;
    case 'failed':
      colorCode.color = '#ef5350';
      colorCode.background = '#fae6e6';
      break;
    case 'unsubscribed':
      colorCode.color = '#ffffff';
      colorCode.background = '#F3AE59';
      break;
    case 'error':
      colorCode.color = '#ef5350';
      colorCode.background = '#fae6e6';
      break;
    case 'bounced':
      colorCode.color = '#ef6c00';
      colorCode.background = '#fff3e0';
      break;
  }
  return colorCode;
};

export const capitalizeName = (name) => {
  return name?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const getContactName = (contact) => {
  const name = `${contact?.fname} ${contact?.lname}`;
  return capitalizeName(name);
};

export const getActivityTypeIcon = (type, fontSize = '1.5rem') => {
  let icon = '';
  switch (type) {
    case 'CONTACT_ADDED':
      icon = <PersonAddIcon style={{ color: '#3f51b5' }} />;
      break;

    case 'CONTACT_UPDATED':
      icon = <SaveAsIcon style={{ color: '#3f51b5' }} />;
      break;

    case 'Enrolled_In_Sequence':
      icon = <BadgeIcon style={{ color: '#795548' }} />;
      break;

    case 'EMAIL_UPDATED':
      icon = <MarkEmailUnreadIcon style={{ color: '#FFA726' }} />;
      break;

    case 'selectEmailTemplate':
      icon = <ContactMailIcon style={{ color: '#FFA726' }} />;
      break;
    case 'email':
      icon = <EmailIcon style={{ fill: '#42A5F5', fontSize }} />;
      break;

    case 'linkedin':
    case 'linkedinConnection':
    case 'linkedinMessage':
    case 'linkedinMail':
    case 'linkedinViewProfile':
      icon = <LinkedInIcon style={{ fill: '#1282B2', fontSize }} />;
      break;
    case 'call':
      icon = <CallIcon style={{ fill: '#66BB6A', fontSize }} />;
      break;
    case 'sms':
      icon = <SmsIcon style={{ fill: '#00bcd4', fontSize }} />;
      break;
    case 'general':
    default:
      icon = <TaskIcon style={{ fill: '#3f51b5', fontSize }} />;
      break;
  }
  return icon;
};

export const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'users': {
      newLabel = {
        type: 'users',
        title: 'Enrolled by',
        isSingle: true,
        showName: true,
      };
      break;
    }
    // case 'created_by': {
    //   newLabel = {
    //     type: 'created_by',
    //     title: 'Created by',
    //   };
    //   break;
    // }
    // case 'type': {
    //   newLabel = {
    //     type: 'type',
    //     title: 'Type',
    //   };
    //   break;
    // }
    //
    // case 'access_level': {
    //   newLabel = {
    //     type: 'access_level',
    //     title: 'Access level',
    //   };
    //   break;
    // }
    // case 'in_progress': {
    //   newLabel = {
    //     type: 'in_progress',
    //     title: 'In Progress',
    //   };
    //   break;
    // }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

export const parseContactFilterData = (data, tab) => {
  let filterListData = Object.keys(data);
  filterListData = filterListData
    .filter((e) => e !== 'complete_date' && e !== 'enrolled_by' && e !== 'enrolled_date')
    .map((k) => ({
      name: k,
      data: data[k],
      optionLabel: 'name',
      optionValue: 'id',
      ...getFilterLabel(k, data),
    }));

  // if (tab?.name != 'In Progress' || tab.id == 9 || tab.id == 6) {
  //   filterListData.push({
  //     name: 'completeDate',
  //     control: 'dateRangePicker',
  //     isSingle: true,
  //     title: 'Completed Date',
  //     type: 'completeDate',
  //     from: 'completeFrom',
  //     to: 'completeTo',
  //   });
  // }
  return filterListData;
};

export const getSequenceTip = (sequenceEnrollment) => {
  let sequenceTip = '';
  if (sequenceEnrollment && sequenceEnrollment.length > 0) {
    switch (sequenceEnrollment[0].status) {
      case sequenceEnrollmentStatus.IN_PROGRESS:
        sequenceTip = `Contact enrolled in sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.COMPLETED:
        sequenceTip = `Contact has completed sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.HOLD:
        sequenceTip = `Contact sequence - ${sequenceEnrollment[0]?.sequence.name} has been put on hold`;
        break;
      case sequenceEnrollmentStatus.BOUNCED:
        sequenceTip = `An email has bounced for this contact. Please update email ID.`;
        break;
      case sequenceEnrollmentStatus.INTERESTED:
        sequenceTip = `Contact has been marked as interested`;
        break;
      case sequenceEnrollmentStatus.NOT_INTERESTED:
        sequenceTip = `Contact has been marked as not interested from sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.UNSUBSCRIBED:
        sequenceTip = `Contact has marked unsubscribe from sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
    }
  } else {
    sequenceTip = 'Contact has not been sequenced yet';
  }
  return sequenceTip;
};

export const getFormattedPhoneNumber = (phoneNumberString, countryCodeIncluded = true) => {
  if (phoneNumberString?.[0] === '+') phoneNumberString = phoneNumberString.slice(1);
  if (
    typeof phoneNumberString !== 'string' ||
    isNaN(phoneNumberString) ||
    phoneNumberString.length < 3
  )
    return { err: 'Invalid Number' };
  let countryCode = '';
  let isCountryCodeFound = false;
  let phoneNumber = '';
  let formatedPhoneNumber = '';
  if (countryCodeIncluded) {
    const rawCoutryCode = phoneNumberString.slice(0, 3);
    rawCoutryCode.split('').forEach((element) => {
      if (isCountryCodeFound) return;
      countryCode += element;
      if (Object.keys(countryCodes)?.includes(countryCode)) isCountryCodeFound = true;
    });
    if (!isCountryCodeFound) return { err: "Invalid number or country code doesn't exist" };
  }
  let countryData = countryCodes[countryCode];
  phoneNumber = phoneNumberString.substring(countryCode.length, phoneNumberString.length);
  let mask = countryData?.mask ? countryData.mask : '*** ***-****';
  formatedPhoneNumber = mask
    ?.split('*')
    ?.map((ele, index, arr) => {
      if (arr?.length === index + 1) {
        return phoneNumber?.substring(index);
      } else if (phoneNumber.length < index + 1) return;
      return ele + phoneNumber?.[index];
    })
    ?.join('');
  return {
    ...(countryCodeIncluded
      ? {
          countryCode: `+${countryCode}`,
          countryCodeName: countryData?.countryNameCode,
          formattedNoWithCC: `+${countryCode} ${formatedPhoneNumber}`,
        }
      : {}),
    phoneNumber: phoneNumber,
    formatedPhoneNumber,
  };
};
