import React, { useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Card, CardContent, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import useStyles from './style';
import { fetchAdminDomainLookup, fetchAdminIndustryLookup } from 'src/modules/auth/api/authApis';

function RoleplayView({ tenant, tenantSettings, updateSettings }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      const data = { roleplaySettings: { ...values } };
      updateSettings(data, tenantSettings.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Form
          initialValues={{
            domain: tenantSettings?.roleplaySettings?.domain || [],
            industry: tenantSettings?.roleplaySettings?.industry || [],
            minimumRoleplayScore: tenantSettings?.roleplaySettings?.minimumRoleplayScore || 0,
          }}
          validationSchema={Yup.object().shape({
            domain: Yup.array().test(
              'domain',
              'Please select at least one domain.',
              function (item) {
                return item.length > 0;
              },
            ),
            industry: Yup.array().test(
              'industry',
              'Please select at least one Industry.',
              function (item) {
                return item.length > 0;
              },
            ),
          })}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                style={{ height: '100%' }}
                noValidate
              >
                <Box display="flex" flexDirection="column" height="100%">
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      AI Learning Settings
                    </Typography>
                    <Spacer x={1} y={1} />
                    <Typography variant="body2" color="textSecondary">
                      Choose your domain and industry specialism to ensure every roleplay is
                      personalised to your exact needs.
                    </Typography>
                  </Box>
                  <Spacer x={2} y={2} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.AutoComplete
                        name="domain"
                        options={[]}
                        fullWidth={true}
                        variant="outlined"
                        remoteMethod={(val) => {
                          return fetchAdminDomainLookup(val);
                        }}
                        optLabel="label"
                        optValue="value"
                        label="My domain is"
                        multiple={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.AutoComplete
                        name="industry"
                        options={[]}
                        fullWidth={true}
                        variant="outlined"
                        label="My industry expertise is"
                        remoteMethod={(val) => {
                          return fetchAdminIndustryLookup(val);
                        }}
                        optLabel="label"
                        optValue="value"
                        multiple={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Form.Field.Number
                          name="minimumRoleplayScore"
                          floatNumber
                          fullWidth={true}
                          variant="outlined"
                          label="Minimum Score"
                        />
                        <Typography>
                          The minimum score required to complete the roleplay. example: 3.5
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Box mt={2} display="flex">
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Form>
      </CardContent>
    </Card>
  );
}

export default RoleplayView;
