import { Grid, Box, Typography, Paper, Link, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form } from 'src/components/shared';
import { fetchTenantSettings, updateSettings } from '../../actions/adminActions';
import SettingsItem from '../../components/Settings/SettingsItem';

const SettingsContainer = ({ settingValue, ...props }) => {
  const portalRef = useRef(null);
  useEffect(() => {
    if (!props.tenantSettings?.data?.length) {
      props.fetchTenantSettings();
    }
  }, []);
  const settingsComponents = [
    {
      title: 'Email limits',
      description: 'Protect your domain by limiting the number of emails each mailbox can send.',
      name: 'emailLimit',
    },
    {
      title: 'Email tracking',
      description: 'Set default settings for tracking',
      name: 'tracking',
    },
    {
      title: 'Out of office',
      description: 'Delay next steps in a sequence when you get an OOO response.',
      name: 'outOfOffice',
    },
    {
      title: 'Blocked domains',
      description:
        'Specify which domains you would like blocked. This will prevent emails being sent to the selected organisations.',
      name: 'blockedDomains',
    },
    {
      title: 'Public holidays',
      description: `Add public holiday dates and we won't send emails or LinkedIn messages to your prospects during this time.`,
      name: 'holidays',
    },
    {
      title: 'CRM BCC address',
      description:
        'BCC addresses can be used to facilitate syncing with your CRM / ATS where direct integration is not possible.',
      name: 'crmbcc',
    },
    // {
    //   title: 'Email tracking',
    //   description:
    //     'BCC addresses can be used to facilitate syncing with your CRM / ATS where direct integration is not possible.',
    //   name: 'emailTracking',
    // },
  ];
  return (
    <>
      <Form
        initialValues={{
          tenantSettings: props.tenantSettings,
          updateSettings: props.updateSettings,
          deleteEvent: props.deleteEvent,
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        // onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              ref={portalRef}
              onSubmit={(e) => {
                e.preventDefault();
                // props.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Grid container spacing={2} mt={0} mb={4}>
                {settingsComponents.map((setting, key) => (
                  <Grid item xs={14} key={key}>
                    <SettingsItem
                      item={setting}
                      tenantSettings={props?.values.tenantSettings}
                      updateSettings={props.values.updateSettings}
                      isCheckedSetting={getIsCheckedSettingReMap(
                        setting.name,
                        props.values.tenantSettings.setting,
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          );
        }}
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  const tenantSettings = state.admin.admin.tenantSettings?.data?.length
    ? state.admin.admin.tenantSettings?.data[0]
    : {};
  return {
    tenantSettings,
    settingValue: state.admin.admin.tenantSettings,
  };
};

const getIsCheckedSettingReMap = (name, settingsObj) => {
  switch (name) {
    case 'emailLimit':
      return {
        settingProp: 'emailLimitsEnabled',
        value: settingsObj?.emailLimitsEnabled,
      };
    case 'tracking':
      return {
        settingProp: 'emailTrackingEnabled',
        value: settingsObj?.emailTrackingEnabled,
      };
    case 'outOfOffice':
      return {
        settingProp: 'outOfOfficeEnabled',
        value: settingsObj?.outOfOfficeEnabled,
      };
    case 'blockedDomains':
      return {
        settingProp: 'blockedDomainsEnabled',
        value: settingsObj?.blockedDomainsEnabled,
      };
    case 'holidays':
      return {
        settingProp: 'holidaysEnabled',
        value: settingsObj?.holidaysEnabled,
      };
    case 'crmbcc':
      return {
        settingProp: 'crmbccAddressEnabled',
        value: settingsObj?.crmbccAddressEnabled,
      };
    default:
      return false;
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchTenantSettings: () => dispatch(fetchTenantSettings()),
  updateSettings: (data, id) => dispatch(updateSettings(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
