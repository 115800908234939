import { isEmpty } from 'lodash';
import { createContext, useState, useContext, useEffect } from 'react';
import api from 'src/api';
import { getDifferenceBetweenDates, getRelativeDateRange } from 'src/utils/dateUtils';
import { convertObjectToQuerystring } from 'src/utils/helper';
const dashContext = createContext({});

import usePersistState from 'src/components/hooks/usePersistState';

export const useDashboardStore = () => {
  const {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
  } = useContext(dashContext);
  return {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
  };
};

export const DashboardProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    sequenceReport: false,
    sequenceStats: false,
    userStats: false,
    teamStats: false,
  });
  const [sequenceReport, setSequenceReport] = useState({});
  const [makeChartTypeDisable, setMakeChartTypeDisable] = useState([]);
  const [filters, setFilters] = usePersistState('seqDashboardFilters', {});
  const [chartType, setChartType] = usePersistState('seqDashboardChartType', 'daily');
  const [sequenceStats, setSequenceStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  const [userStats, setUserStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  const [teamStats, setTeamStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  useEffect(() => {
    let persistedData = localStorage.getItem('seqDashboardFilters');
    if (
      persistedData &&
      persistedData !== '' &&
      typeof persistedData !== 'undefined' &&
      persistedData != 'undefined'
    ) {
      const data = JSON.parse(persistedData);
      if (!data?.createdAt) {
        setFilters({
          ...data,
          createdAt: { id: 'last30Days', name: 'Last 30 days' },
        });
      }
    }
  }, []);

  const getFilter = () => {
    let filter = {};

    Object.keys(filters).map((key, index) => {
      if (filters[key] && key === 'createdAt' && !isEmpty(filters[key])) {
        const dateRange = getRelativeDateRange(filters[key]);
        filter = {
          ...filter,
          createdAt_gte: dateRange?.start,
          createdAt_lte: dateRange?.end,
        };
      } else if (filters[key] && key === 'sequences' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          sequences: filters[key]?.map((sequence) => sequence.id),
        };
      } else if (filters[key] && key === 'teams' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          teams: filters[key]?.map((team) => team.id),
        };
      } else if (filters[key] && key === 'enrolledby' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          enrolledby: filters[key]?.map((assignedTo) => assignedTo.id),
        };
      }
    });
    return filter;
  };

  const fetchSequenceStats = async (newPage, sort) => {
    try {
      setLoading({
        ...loading,
        sequenceStats: true,
      });
      setSequenceStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };

      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }

      const query = convertObjectToQuerystring(filter);
      const response = await api(`sequence/dashboardStats?${query}`, null, 'get');
      setLoading({
        ...loading,
        sequenceStats: false,
      });

      setSequenceStats((prevState) => ({
        ...prevState,
        data: response?.sequences,
        paging: {
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
          count: response?.total?.value,
        },
      }));
    } catch (error) {
      setLoading({
        ...loading,
        sequenceStats: false,
      });
      setSequenceStats({
        ...sequenceStats,
        data: [],
        paging: {
          ...sequenceStats?.paging,
          count: 0,
        },
      });
    }
  };

  const fetchUserStats = async (newPage, sort) => {
    try {
      setLoading({
        ...loading,
        userStats: true,
      });
      setUserStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = await api(`user/dashboardStats?${query}`, null, 'get');

      setLoading({
        ...loading,
        userStats: false,
      });
      setUserStats((prevState) => ({
        ...prevState,
        data: response?.users,
        paging: {
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
          count: response?.total?.value,
        },
      }));
    } catch (error) {
      setLoading({
        ...loading,
        userStats: false,
      });
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };
  const fetchTeamStats = async (newPage, sort) => {
    try {
      setLoading({
        ...loading,
        teamStats: true,
      });
      setTeamStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));
      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = await api(`team/dashboardStats?${query}`, null, 'get');

      setLoading({
        ...loading,
        userStats: false,
      });
      setTeamStats((prevState) => ({
        ...prevState,
        data: response?.teams,
        paging: {
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
          count: response?.total?.value,
        },
      }));
    } catch (error) {
      console.error('Error: ', error);
      setLoading({
        ...loading,
        teamStats: false,
      });
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };

  useEffect(() => {
    // fetchSequenceStats(sequenceStats.paging);
    // fetchUserStats(userStats.paging);
    fetchSequenceReport();
  }, [filters]);

  const fetchSequenceReport = async () => {
    try {
      setLoading({
        ...loading,
        sequenceReport: true,
      });
      let filter = getFilter();

      if (filter?.createdAt_gte && filter?.createdAt_lte) {
        const diff = getDifferenceBetweenDates(filter?.createdAt_gte, filter?.createdAt_lte);
        let fieldDisables = [];
        if (diff > 730) {
          fieldDisables.push('daily');
          fieldDisables.push('weekly');
          if (chartType === 'daily' || chartType === 'weekly') {
            setChartType('monthly');
          }
        } else if (diff > 360) {
          fieldDisables.push('daily');
          if (chartType === 'daily') {
            setChartType('weekly');
          }
        }
        setMakeChartTypeDisable(fieldDisables);
      } else {
        setMakeChartTypeDisable([]);
      }
      if (chartType) {
        filter['chartType'] = chartType;
      }
      let query = '';
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = await api(`board/sequenceReport?${query}`, null, 'get');
      setSequenceReport(response);
      setLoading({
        ...loading,
        sequenceReport: false,
      });
    } catch (error) {
      setSequenceReport({});
      setLoading({
        ...loading,
        sequenceReport: false,
      });
    }
  };

  return (
    <dashContext.Provider
      value={{
        filters,
        setFilters,
        sequenceReport,
        sequenceStats,
        userStats,
        teamStats,
        loading,
        setChartType,
        chartType,
        fetchSequenceReport,
        fetchSequenceStats,
        fetchUserStats,
        fetchTeamStats,
        makeChartTypeDisable,
      }}
    >
      {children}
    </dashContext.Provider>
  );
};

export const withDashboardProvider = (Component) => (props) =>
  (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  );
