import React from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment, Alert } from '@mui/material';
import { Form, Button } from 'src/components/shared';

import MailIcon from '@mui/icons-material/Mail';
import LockIcon from '@mui/icons-material/Lock';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginError } from '../../actions/authActions';
import usePersistState from '../../../../components/hooks/usePersistState';

export default function LoginView(props) {
  const dispatch = useDispatch();
  const isLoginError = useSelector((state) => state?.auth?.user?.userLoginError ?? null);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = usePersistState('seqDashboardFilters', {});
  const onSubmit = async (data, form) => {
    try {
      const res = await props.login(data);
      if (res?.user && !filters?.enrolledby?.length) {
        setFilters({ ...filters, enrolledby: [res?.user] });
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        Login
      </Typography>
      <div style={{ height: 10 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        {isLoginError && (
          <>
            <Alert severity="error" onClose={() => dispatch(userLoginError())}>
              {isLoginError}
            </Alert>
            <div style={{ height: 10 }} />
          </>
        )}
        <Form
          initialValues={{
            identifier: '',
            password: '',
            files: [],
          }}
          validationSchema={Yup.object().shape({
            identifier: Yup.string()
              .trim()
              .email('Enter a valid email address')
              .required('Please enter your email address!'),
            password: Yup.string().required('Please enter your password!'),
          })}
          onSubmit={async (values, form) => {
            setLoading(true);
            const res = await onSubmit(values, form);
            setLoading(false);
            form.prevent;
          }}
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
              >
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="identifier"
                  placeholder="Email"
                  disabled={loading}
                  autoComplete="on"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  type="password"
                  name="password"
                  placeholder="Password"
                  disabled={loading}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    fullWidth
                    size="large"
                  >
                    Login
                  </Button>
                </Box>

                <Box
                  mt={3}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Link to="/auth/forgot-password">
                    <Typography variant="body2" color="secondary" style={{ cursor: 'pointer' }}>
                      Forgot password?
                    </Typography>
                  </Link>
                  <Box mt={1} display="flex" justifyContent="flex-end">
                    <Typography color="textSecondary" variant="body2">
                      Don't have an account?{' '}
                      <a target="_blank" href="https://superreach.com/book-a-demo/">
                        <Typography
                          color="secondary"
                          variant="body2"
                          display="inline"
                          component="span"
                        >
                          Sign up
                        </Typography>
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Form>
      </div>
    </React.Fragment>
  );
}
