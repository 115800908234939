import React from 'react';
import images from 'src/config/images';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Rating, Grid } from '@mui/material';
import { BackButton, Empty } from 'src/components/App';

import { Spacer, Button, Form } from 'src/components/shared';
import {
  SwimLaneContainer,
  SwimLaneIcon,
  SwimLaneIconContainer,
  SwimLaneContent,
  MainGrid,
  Left,
  Right,
  PaperWrap,
  PlusIcon,
} from './styles';

import Categories from './Categories';
import AddIcon from '@mui/icons-material/Add';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import Item from './Item';

function SwimLane({ resetAction, onClose, editBoard, putBoard, board }) {
  const [loading, setLoading] = React.useState(false);

  const makeSwimlanes = async () => {
    try {
      setLoading(true);
      const update = await putBoard(board?.id, { type: 'swimlanes' });
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box>
      <SwimLaneContainer>
        <SwimLaneIconContainer>
          <SwimLaneIcon src={images.app.pipelineIcon} />
        </SwimLaneIconContainer>
        <SwimLaneContent>
          <Box>
            <BackButton onClick={resetAction} />
            <Spacer x={1} y={1} />
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box>
                <Typography variant="h2">Pipeline Format</Typography>
                <Spacer x={1} y={1} />
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                </Typography>
              </Box>
              <Spacer x={2} y={2} />
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<ViewKanbanIcon />}
                  onClick={makeSwimlanes}
                  loading={loading}
                >
                  Use Swimlanes
                </Button>
              </Box>
            </Box>
          </Box>
        </SwimLaneContent>
      </SwimLaneContainer>
    </Box>
  );
}

export default SwimLane;
