import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, Stack, ButtonBase, Divider } from '@mui/material';
import { Button, Stepper } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useWindowDimensions, useViewport } from 'src/components/hooks';
import BoardForm from './BoardForm';
import Templates from './Templates';
import Widgets from './Widgets';
import TemplateDetails from './TemplateDetails';
import Swimlane from './Swimlane';
import BoardStages from '../BoardStages';
import { Header, Content } from './styles';

const BoardFormView = ({ editBoard, postBoard, putBoard, setDeleteBoard, board, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [action, setAction] = useState('');
  const [activeBoardTemplate, setActiveBoardTemplate] = useState({});
  const [boardType, setBoardType] = useState('widgets');
  const viewport = useViewport();
  const steps = useMemo(() => {
    if (boardType === 'widgets') {
      return ['Board Details', 'Board Widgets'];
    }
    return ['Board Details', 'Board Stages'];
  }, [boardType]);

  useEffect(() => {
    if (props.defaultAction === 'widgets') {
      setAction('widgets');
    }
    if (props.defaultAction === 'templates') {
      setAction('templates');
    }
  }, [props.defaultAction]);

  const handleBoardTemplateClick = (template) => {
    setAction('templateDetails');
    setActiveBoardTemplate(template);
  };

  const onClose = () => {
    setActiveStep(0);
    setAction('');
    setActiveBoardTemplate({});
    props.onClose();
  };

  const handleWidgetsList = () => {
    setAction('widgets');
  };

  const resetAction = (template) => {
    if (props.defaultAction === 'templates' && action === 'widgets') {
      setAction('templates');
    } else if (props.defaultAction === 'templates' && action === 'templateDetails') {
      setAction('templates');
    } else if (action === 'swimlanes') {
      setAction('templates');
    } else {
      setAction('');
      setActiveBoardTemplate({});
    }
  };

  const title = React.useMemo(() => {
    switch (props.defaultAction) {
      case 'widgets':
        return 'Add widget';
        break;

      case 'templates':
        return 'Templates';
        break;

      default:
        return editBoard?.id ? 'Edit Board' : 'Create Board';
        break;
    }
  }, [props.defaultAction, editBoard]);

  const addWidget = (widget) => {
    return props.addWidgetsToBoard(widget, viewport);
  };

  const removeWidget = (widgetId) => {
    return props.removeWidgetsFromBoard(widgetId);
  };

  const useTemplate = (template) => {
    return props.useTemplateForBoard(template);
  };

  const handleSwimLaneView = () => {
    setAction('swimlanes');
  };

  const handlePostBoard = async (board) => {
    return new Promise(async (resolve, reject) => {
      try {
        board.pinned = true;
        const addedBoard = await postBoard(board);
        if (addedBoard.type == 'widgets') {
          setAction('widgets');
        } else {
          setAction('stages');
        }
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  return (
    <>
      <Header>
        <Box flex={1}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Box flex={1}>
          {!editBoard?.id &&
          props.defaultAction !== 'widgets' &&
          props.defaultAction !== 'templates' ? (
            <Stepper steps={steps} activeStep={activeStep} onChange={() => {}} />
          ) : null}
        </Box>
        <Box display="flex" flex={1} alignItems="flex-end" justifyContent="flex-end">
          <Button iconButton size="small" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
      </Header>

      <Divider />
      <Content>
        {action === 'stages' ? (
          <Box mt={2} sx={{ width: '60%', margin: '10px auto' }}>
            <BoardStages board={{}} onClose={onClose} />
          </Box>
        ) : null}

        {action === 'templateDetails' ? (
          <TemplateDetails
            onClose={onClose}
            templateCategories={props.templateCategories}
            boardCategories={props.boardCategories}
            loading={props.loading}
            resetAction={resetAction}
            boardTemplate={activeBoardTemplate}
            useTemplate={useTemplate}
            handleBoardTemplateClick={handleBoardTemplateClick}
          />
        ) : null}

        {action === 'widgets' ? (
          <Widgets
            onClose={onClose}
            defaultAction={props.defaultAction}
            templateCategories={props.templateCategories}
            boardCategories={props.boardCategories}
            loading={props.loading}
            resetAction={resetAction}
            boardTemplate={activeBoardTemplate}
            addWidget={addWidget}
            removeWidget={removeWidget}
          />
        ) : null}

        {action === 'templates' ? (
          <Templates
            handleSwimLaneView={handleSwimLaneView}
            postBoard={postBoard}
            putBoard={putBoard}
            editBoard={editBoard}
            setDeleteBoard={setDeleteBoard}
            onClose={onClose}
            templateCategories={props.templateCategories}
            boardCategories={props.boardCategories}
            loading={props.loading}
            handleBoardTemplateClick={handleBoardTemplateClick}
            handleWidgetsList={handleWidgetsList}
          />
        ) : null}

        {action === 'swimlanes' ? (
          <Swimlane
            resetAction={resetAction}
            onClose={onClose}
            putBoard={putBoard}
            editBoard={editBoard}
            board={board}
          />
        ) : null}

        {action !== 'templateDetails' &&
        action !== 'widgets' &&
        action !== 'templates' &&
        action !== 'stages' &&
        action !== 'swimlanes' ? (
          <>
            {activeStep === 0 ? (
              <Box px={2} mb={5} pt={0} mt={1}>
                <BoardForm
                  postBoard={handlePostBoard}
                  putBoard={putBoard}
                  editBoard={editBoard}
                  setDeleteBoard={setDeleteBoard}
                  onClose={onClose}
                  setActiveStep={setActiveStep}
                  setBoardType={setBoardType}
                />
              </Box>
            ) : null}

            {activeStep === 1 ? (
              <Box>
                <Templates
                  handleSwimLaneView={handleSwimLaneView}
                  postBoard={postBoard}
                  putBoard={putBoard}
                  editBoard={editBoard}
                  setDeleteBoard={setDeleteBoard}
                  onClose={onClose}
                  templateCategories={props.templateCategories}
                  boardCategories={props.boardCategories}
                  loading={props.loading}
                  handleBoardTemplateClick={handleBoardTemplateClick}
                  handleWidgetsList={handleWidgetsList}
                />
              </Box>
            ) : null}
          </>
        ) : null}
      </Content>
    </>
  );
};

export default BoardFormView;
