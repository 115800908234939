import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Tooltip, Typography, Box } from '@mui/material';
import { Button } from 'src/components/shared';

import UserGridActions from './UserGridActions';
import { capitalizeFirstLetter } from 'src/utils/helper';
import VisibilityIcon from '@mui/icons-material/Visibility';

import moment from 'moment';
import { dateTimeFormat } from 'src/config';

export function useColumns(data, drawerToggle, props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        name: 'view',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box justifyContent="center" display="flex">
                <Tooltip title="View User">
                  <VisibilityIcon
                    fontSize={'small'}
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                    onClick={() => {
                      drawerToggle({ edit: row, open: true });
                    }}
                  />
                </Tooltip>
              </Box>
            );
          },
        },
      },
      {
        label: 'Name',
        name: 'fname',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { width: 200 },
          }),
          setCellProps: () => ({
            style: { width: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <>
                <Link
                  className="text-truncate td-anchor-content-ellipsis"
                  to={`/admin/user/${rData?.id}/details?otheruser=true`}
                  target="_blank"
                >
                  <Typography variant="body1" color="secondary">
                    {`${capitalizeFirstLetter(rData?.fname)} ${capitalizeFirstLetter(
                      rData?.lname,
                    )}`}
                  </Typography>
                </Link>
                <Typography color="textSecondary" variant="body2">
                  {rData?.email || ''}
                </Typography>
              </>
            );
          },
        },
      },
      {
        label: 'User type',
        name: 'userType',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 80 },
          }),
          setCellProps: () => ({
            style: { minWidth: 80 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Typography color="textPrimary" variant="body2">
                {rData?.acl?.accessLevel === 'teamLead'
                  ? 'Team Leader'
                  : rData?.acl?.accessLevel || '-'}
              </Typography>
            );
          },
        },
      },
      // {
      //   label: 'Access',
      //   name: 'access',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     draggable: true,
      //     setCellHeaderProps: (value) => ({
      //       style: { minWidth: 80 },
      //     }),
      //     setCellProps: () => ({
      //       style: { minWidth: 80 },
      //     }),
      //     customBodyRenderLite: (dataIndex) => {
      //       const user = data[dataIndex];
      //       let arr = [];
      //       if (user?.lmsannual === true || user?.lmsmonthly === true) arr.push('AI Learning');
      //       if (user?.outreachAnnual === true || user?.outreachMonthly === true)
      //         arr.push('Outreach');
      //       if (arr.length) {
      //         return (
      //           <Typography color="textPrimary" variant="body2">
      //             {arr.join(' | ')}
      //           </Typography>
      //         );
      //       }
      //       return '-';
      //     },
      //   },
      // },
      {
        label: 'Team',
        name: 'team',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 80 },
          }),
          setCellProps: () => ({
            style: { minWidth: 80 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Typography color="textPrimary" variant="body2">
                {capitalizeFirstLetter(rData?.team[0]?.name || '-')}
              </Typography>
            );
          },
        },
      },
      // {
      //   label: 'Last login',
      //   name: 'lastLogin',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     draggable: false,
      //     setCellHeaderProps: (value) => ({
      //       style: { minWidth: 80 },
      //     }),
      //     setCellProps: () => ({
      //       style: { minWidth: 80 },
      //     }),
      //     customBodyRenderLite: (dataIndex) => {
      //       const rData = data[dataIndex];
      //       return (
      //         <Tooltip title={moment(rData?.createdAt).format(dateTimeFormat)}>
      //           <Typography color="textPrimary" variant="body2">
      //             {moment(rData?.createdAt).fromNow()}
      //           </Typography>
      //         </Tooltip>
      //       );
      //     },
      //   },
      // },
      {
        label: 'User status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 30 },
          }),
          setCellProps: () => ({
            style: { minWidth: 30 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Tooltip
                title={`Last Activity:
                ${moment(rData?.createdAt).format('ddd DD, MMM')}`}
              >
                <Typography
                  color={
                    rData?.status === 'active'
                      ? '#2E7D32'
                      : rData?.status === 'pending'
                      ? '#000000DE'
                      : '#D32F2F'
                  }
                >
                  {rData?.status === 'active'
                    ? 'Active'
                    : rData?.status === 'pending'
                    ? 'Pending'
                    : 'Inactive'}
                </Typography>
              </Tooltip>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 15, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 15, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = data.find((user) => user.id === value);
            return (
              <UserGridActions
                drawerToggle={drawerToggle}
                status={row?.status}
                row={row}
                {...props}
              />
            );
          },
        },
      },
    ]);
  }, [data]);

  return columns;
}
