import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Typography, Alert, FormHelperText, Stack } from '@mui/material';
import { Drawer, Button, Spacer, Form } from 'src/components/shared';
import { Empty } from 'src/components/App';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import {
  fetchSequencesLookup,
  enrollContactsToSequenceTest,
} from 'src/modules/sequence/api/sequenceApi';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import toast from 'src/utils/toast';
import AppLoader from 'src/components/App/Loader';

function ContactActionDrawer({ action, selectedContacts, contactBulkAction, ...props }) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sequence, setSequence] = useState({});
  const [sequences, setSequences] = useState([]);
  const [errors, setErrors] = useState([]);

  const isDrawerOpen = action && typeof action !== 'undefined';

  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const emailConnected = useMemo(
    () => (user?.nlAccessToken && user?.nlAccessToken !== '' ? true : false),
    [user],
  );
  useEffect(() => {
    if (isDrawerOpen && action === 'addToSequence') checkErrors();
    return () => {
      setErrors([]);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    switch (true) {
      case typeof sequence?.id === 'string':
        setTitle(`${sequence.name}`);
        break;

      case action === 'addToSequence':
        setTitle('Add to sequence');
        break;

      case action === 'removeFromSequence':
        setTitle(`Remove from Sequence`);
        break;

      case action === 'removeFromDraft':
        setTitle(`Remove from Draft`);
        break;

      case action === 'assignUser':
        setTitle(`Assign User`);
        break;

      default:
        break;
    }
    const fetchSequences = async () => {
      const newSequences = await fetchSequencesLookup('');
      const organisationSequences = [];
      const privateSequences = [];
      const teamSequences = [];
      newSequences.forEach((sequence) => {
        if (sequence.accessType === 'organisation') organisationSequences.push(sequence);
        else if (sequence.accessType === 'private') privateSequences.push(sequence);
        else teamSequences.push(sequence);
      });
      setSequences([...organisationSequences, ...privateSequences, ...teamSequences]);
    };
    if (action === 'addToSequence' && !sequences?.length) fetchSequences();
  }, [action, sequence]);

  if (!action || action == '') return null;

  const onClose = () => {
    setErrors([]);
    setSequence();
    setTitle('');
    setLoading(false);
    props.onClose();
  };

  const enrolAll = async (payload) => {
    setBtnLoading(true);
    await contactBulkAction('enrollAll', enrolledContacts().notEnrolled, payload);
    setBtnLoading(false);
    onClose();
  };

  const handleSubmit = async (payload) => {
    try {
      setBtnLoading(true);
      if (action === 'addToSequence') {
        if (enrolledContacts().notEnrolled?.length)
          await contactBulkAction(action, enrolledContacts().notEnrolled, payload);
        else {
          toast.error('All contacts are already in sequence', 'tr');
          return setBtnLoading(false);
        }
      } else if (action === 'removeFromDraft') {
        const contactids = selectedContacts?.filter((contact) => contact.status === 'draft');
        if (!contactids?.length)
          toast.error(
            'Contact(s) need to be in draft status before initiating the removal from the draft.',
            'tr',
          );
        else await contactBulkAction(action, contactids, payload);
      } else {
        await contactBulkAction(action, selectedContacts, payload);
      }
      onClose();
    } finally {
      setBtnLoading(false);
    }
  };

  const markAllCompleted = () => {
    const data = {
      status: 'completed',
    };
    handleSubmit(data);
  };

  const enrolledContacts = () => {
    let alreadyEnrolled = [];
    let notEnrolled = [];
    errors.forEach((item) => {
      if (item.errors.find((err) => err.type === 'contactAlreadyEnrolled'))
        alreadyEnrolled.push(item);
      else notEnrolled.push(item);
    }, []);
    return { alreadyEnrolled, notEnrolled };
  };

  async function checkErrors() {
    try {
      setLoading(true);
      const res = await enrollContactsToSequenceTest(
        undefined,
        selectedContacts.map((contact) => ({ id: contact.id })),
      );
      setLoading(false);
      if (res?.enrollmentContacts.length) setErrors(res?.enrollmentContacts);
      else setErrors([]);
      setLoading(false);
    } catch (error) {
      setErrors([]);
      setLoading(false);
    }
  }

  const getContent = () => {
    switch (action) {
      case 'removeFromSequence':
        return (
          <Box display={'flex'} flexDirection="column" justifyContent="space-between" height="100%">
            <Box>
              <Spacer x={2} y={2} />
              <Typography>
                Are you sure you want to remove selected contacts from all sequences? This action
                cannot be undone.
              </Typography>
              <Spacer x={2} y={2} />
              <Alert severity="warning">
                This will stop all scheduled communications with the selected contacts.
              </Alert>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Spacer x={1} y={1} />
              <Button
                variant="contained"
                color="secondary"
                loading={loading || btnLoading}
                onClick={markAllCompleted}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        );

      case 'removeFromDraft':
        return (
          <Box display={'flex'} flexDirection="column" justifyContent="space-between" height="100%">
            <Box>
              <Spacer x={2} y={2} />
              <Typography>
                Are you sure you want to remove selected contacts from all draft? This action cannot
                be undone.
              </Typography>
              {/* <Spacer x={2} y={2} />
              <Alert severity="warning">
                This will stop all scheduled communications with the selected contacts.
              </Alert> */}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Spacer x={1} y={1} />
              <Button
                variant="contained"
                color="secondary"
                loading={loading || btnLoading}
                onClick={markAllCompleted}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        );

      case 'addToSequence':
        return emailConnected === true ? (
          <Form
            initialValues={{
              sequence: {},
            }}
            validationSchema={Yup.object().shape({})}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              handleSubmit(data);
            }}
          >
            {({ ...formProps }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formProps.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  {loading ? (
                    <Stack justifyContent={'center'} alignItems={'center'} height={'75vh'}>
                      <AppLoader />
                    </Stack>
                  ) : (
                    <Stack justifyContent={'space-between'} height={'100%'}>
                      <Box display={'flex'} flexDirection="column" justifyContent="space-between">
                        {enrolledContacts()?.alreadyEnrolled?.length !== 0 && (
                          <React.Fragment>
                            <Alert severity="info">
                              {enrolledContacts()?.alreadyEnrolled?.length} Contacts couldn't be
                              enrolled because they are already in a sequence.
                            </Alert>
                            <Spacer y={2} />
                          </React.Fragment>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          {selectedContacts.length} contact{selectedContacts.length !== 1 && 's'}{' '}
                          selected
                        </Typography>
                        <Form.Field.AutoComplete
                          multiple={false}
                          fullWidth
                          options={sequences}
                          variant="outlined"
                          name="sequence"
                          label="Select sequence"
                          groupBy={(option) =>
                            option?.accessType === 'organisation'
                              ? 'Organisation'
                              : option?.accessType === 'private'
                              ? 'Private'
                              : 'Team'
                          }
                          optLabel="name"
                          optValue="id"
                          onChange={setSequence}
                        />
                        <FormHelperText>
                          Select from active sequences you have permission to access.
                        </FormHelperText>
                      </Box>

                      <Stack direction={'row'} spacing={2} mt={2} justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => enrolAll(formProps?.values)}
                          disabled={!formProps?.values?.sequence?.id}
                          loading={btnLoading}
                        >
                          Enrol all prospects
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={!formProps?.values?.sequence?.id}
                          loading={btnLoading}
                        >
                          Launch personalisation
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </form>
              );
            }}
          </Form>
        ) : (
          <Empty
            title="Connect Mailbox"
            description="To send emails via SuperReach your first need to connect your mailbox."
            buttonTitle="Connect"
            buttonProps={{
              variant: 'contained',
              color: 'secondary',
              endIcon: <OpenInNewIcon />,
            }}
            onClick={() => {
              onClose();
              history.push('/profile/email');
            }}
          />
        );

      case 'assignUser':
        return (
          <Form
            initialValues={{
              assignedTo: '',
            }}
            validationSchema={Yup.object().shape({
              assignedTo: Yup.object().shape({
                id: Yup.string().required('Please select assignee!'),
              }),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.assignedTo = data.assignedTo.id;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      showAvatar={false}
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchUsersLookup(search);
                      }}
                      name="assignedTo"
                      label="Assigned to"
                      optLabel="name"
                      optValue="id"
                      tip="Change assigned user for all selected tasks."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="outlined" color="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Spacer x={1} y={1} />
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );

      default:
        break;
    }
  };

  return (
    <Drawer
      title={title}
      description={'Choose sequence, personalise and enrol'}
      open={isDrawerOpen}
      onClose={onClose}
      sx={{
        '.drawer-header': {
          padding: '30px',
          paddingBottom: '24px',
        },
      }}
    >
      {getContent()}
    </Drawer>
  );
}

export default ContactActionDrawer;
