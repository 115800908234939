import React, { useState } from 'react';
import { Tabs } from 'src/components/App';

import { dashboardTabs1 } from '../../config';
import { Container, Box } from '@mui/material';
import { FixedTitleBar } from './styles';

import { withDashboardProvider } from '../../utils/dashboardStore';

import Sequences from '../../components/dashboard/Sequences';

function DashboardView() {
  const [currentTab, setCurrentTab] = useState('sequences');

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  return (
    <>
      <FixedTitleBar>
        <Tabs
          tabs={dashboardTabs1}
          onChange={onTabChange}
          value={currentTab}
          style={{ marginLeft: '16px' }}
        />
      </FixedTitleBar>
      <Container>
        <Box mt={8}>
          <Sequences />
        </Box>
      </Container>
    </>
  );
}

export default withDashboardProvider(DashboardView);
