import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import styled from 'styled-components';
const bg = require(`src/assets/lms/CourseBG.svg`).default;
import darkTheme from 'src/config/darkTheme';
import { ReactComponent as Assigned } from 'src/assets/lms/icons/assigned.svg';

export const AssignedIcon = () => (
  <Assigned style={{ color: darkTheme.palette.secondary.main, width: 20, height: 20 }} />
);

export const Wrapper = styled(ButtonBase)`
  aspect-ratio: 0.82/1;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  &.list-wrapper {
    display: flex;
    align-items: start;
    aspect-ratio: unset;
    justify-content: start;
    gap: 24px;

    .backgroundImage {
      /* width: 328px; */
      aspect-ratio: 1.64/1;
      height: unset;
      background: linear-gradient(180deg, #222 0%, #555 100%);
    }
    .content-wrapper {
      width: 100%;
      gap: 8px;
      padding: 0;

      .header {
        gap: 8px;
        flex-direction: row;
        align-items: center;
      }

      p,
      span {
        margin: 0;
        font-size: 14px;
        text-align: start;
      }
    }
  }
`;

export const TopPill = styled.div`
  width: 80%;
  height: 12px;
  border-radius: 8px 8px 0px 0px;
  background-color: #222222;
  margin: 0 auto;
`;

export const Background = styled.div`
  border-radius: 8px;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: ${(props) => props?.image || ''};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Head = styled.div`
  position: absolute;
  top: 8px;
  width: 100%;
  left: 0;
  z-index: 100;
`;

export const HeadWide = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 100;
`;

export const Content = styled.div`
  position: relative;
  /* height: calc(100% - 50px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  z-index: 101;
  border-radius: 8px 8px 0px 0px;
  /* padding: 16px; */
  width: 100%;
`;

export const ContentLong = styled.div`
  position: relative;
  /* height: calc(100% - 50px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 101;
  border-radius: 8px 8px 0px 0px;
  padding: 16px;
  width: 100%;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  background: var(--Dark-Gradient, linear-gradient(180deg, rgba(9, 13, 17, 0) 0%, #090d11 100%));
  z-index: 100;
  border-radius: 8px;
`;

export const Title = styled(Typography)`
  color: ${darkTheme.palette.text.primary};
  text-align: left;
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  @media (max-width: 786px) {
    font-size: 18px;
  }
`;

export const Type = styled(Typography)`
  color: ${darkTheme.palette.secondary.main};
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
`;

export const SessionImage = styled.img`
  position: absolute;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
`;
