import LMSSessionDetailContainer from '../container/LMSSessionDetailContainer';
import LMSCourseContainer from '../container/LMSCourseContainer';
import LMSPillarContainer from '../container/LMSPillarContainer ';
import LMSSearchResultContainer from '../container/LMSSearchResultContainer';
import LMSCreateTemplateContainer from '../container/LMSCreateTemplateContainer';
import LMSTemplatesContainer from '../container/LMSMyTemplatesContainer';
import LMSCreateNotesContainer from '../container/LMSCreateNotesContainer';
import LMSMyNotesContainer from '../container/LMSMyNotesContainer';
import LMSEditTemplateContainer from '../container/LMSEditTemplateContainer';
import LMSCompetitionContainer from '../container/LMSCompetitionContainer';
import LMSDashboardContainer from '../container/LMSDashboardContainer';
import LeagueScreen from '../components/LMSCompetition/LeagueScreen';
import ReportScreen from '../components/LMSCompetition/ReportScreen';
import LMSRecruitmentContainer from '../container/LMSRecruitmentContainer';
import LMSListContainer from '../container/LMSListContainer';
import LMSWatchHistoryContainer from '../container/LMSWatchHistoryContainer';

import LMSHome from '../container/home';
import LMSHomeContainer from '../container/LMSHomeContainer';
import LMSInProgress from '../container/inProgress';
import LMSCompleted from '../container/completed';
import LMSAssignedToMe from '../container/assignedToMe';
import LMSSaved from '../container/saved';

import CompetitionContainer from 'src/modules/compitions/container/Competitions/CompetitionContainer';
import TeamsContainer from 'src/modules/lms/container/teams';

export default [
  {
    title: 'LMS - home',
    component: LMSHome,
    url: '/lms',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - sales',
    component: LMSHomeContainer,
    url: '/lms/sales',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - sales',
    component: LMSRecruitmentContainer,
    url: '/lms/recruitment',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - In Progress',
    component: LMSInProgress,
    url: '/lms/progress',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - Assigned To Me',
    component: LMSAssignedToMe,
    url: '/lms/assigned',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - Completed',
    component: LMSCompleted,
    url: '/lms/completed',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - My List',
    component: LMSSaved,
    url: '/lms/saved',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'LMS - Recruitment',
  //   component: LMSRecruitmentContainer,
  //   url: '/lms/recruitment',
  //   exact: true,
  //   auth: true,
  // },
  {
    title: 'LMS - Dashboard',
    component: LMSDashboardContainer,
    url: '/lms/dashboard',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - Dashboard Details',
    component: LMSDashboardContainer,
    url: '/lms/dashboard/:id',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'LMS - My List',
  //   component: LMSListContainer,
  //   url: '/lms/list',
  //   exact: true,
  //   auth: true,
  // },

  {
    title: 'LMS - My Watch History',
    component: LMSWatchHistoryContainer,
    url: '/lms/watchhistory',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - Teams',
    // component: LMSCompetitionContainer,
    component: TeamsContainer,
    url: '/lms/team/:slug',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'LMS - Competition',
  //   // component: LMSCompetitionContainer,
  //   component: CompetitionContainer,
  //   // url: '/lms/teams/competition',
  //   exact: true,
  //   auth: true,
  // },
  {
    title: 'LMS - League',
    component: LeagueScreen,
    url: '/lms/league/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'LMS - Report',
    component: ReportScreen,
    url: '/lms/report/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'CreateTemplate',
    component: LMSCreateTemplateContainer,
    url: '/lms/createTemplate',
    exact: true,
    auth: true,
  },
  {
    title: 'Templates',
    component: LMSTemplatesContainer,
    url: '/lms/MyTemplates',
    exact: true,
    auth: true,
  },
  {
    title: 'CreateNotes',
    component: LMSCreateNotesContainer,
    url: '/lms/CreateNotes/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'CreateNotes',
    component: LMSCreateNotesContainer,
    url: '/lms/CreateNotes',
    exact: true,
    auth: true,
  },
  {
    title: 'MyNotes',
    component: LMSMyNotesContainer,
    url: '/lms/MyNotes',
    exact: true,
    auth: true,
  },

  {
    title: 'Session',
    component: LMSSessionDetailContainer,
    url: '/lms/session/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Edit Template',
    component: LMSEditTemplateContainer,
    url: '/lms/template/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Course',
    component: LMSCourseContainer,
    url: '/lms/course/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Pillar',
    component: LMSPillarContainer,
    url: '/lms/pillar/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Search',
    component: LMSSearchResultContainer,
    url: '/lms/search',
    exact: true,
    auth: true,
  },
  {
    title: 'Search',
    component: LMSSearchResultContainer,
    url: '/lms/search/:search',
    exact: true,
    auth: true,
  },
  // {
  //   title: 'Task',
  //   component: Task,
  //   url: '/tasks/:type/:id',
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   title: 'Tasks',
  //   component: Tasks,
  //   url: '/task',
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   title: 'Tasks',
  //   component: Tasks,
  //   url: '/task/:id',
  //   exact: true,
  //   auth: true,
  // },
];
