import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Box } from '@mui/material';
import List from '../../components/Inbox/List';
import Messages from '../../components/Inbox/Messages';
import Profile from 'src/modules/contacts/components/Contact/Profile';
import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import Skeleton from 'src/modules/contacts/components/Contact/Skeleton';
import Empty from '../../components/Inbox/Empty';
import { isArray, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { fetchSequenceEnrollment } from 'src/modules/contacts/actions/contactActions';

const InboxView = ({ sent, loading, taskReplies, ...props }) => {
  const { paging, filters, data } = sent;
  const isAdmin = props.user?.isAdmin || false;
  const history = useHistory();
  const dispatch = useDispatch();

  const allFilters = [
    {
      key: 'scheduledAt',
      title: 'Sent',
      type: 'relativeDateRange',
      custom: true,
      options: [
        { id: 'today', name: 'Sent Today' },
        { id: 'yesterday', name: 'Sent Yesterday' },
        { id: 'last7days', name: 'Sent Last 7 days' },
        { id: 'last10days', name: 'Sent Last 10 days' },
      ],
      isPrimary: true,
    },
    ...(isAdmin
      ? [
          {
            key: 'user',
            title: 'User',
            type: 'dropdown',
            remote: true,
            optLabel: 'name',
            optValue: 'id',
            cancellable: false,
            searchable: true,
            isPrimary: true,
            remoteMethod: async (value) => {
              return fetchUsersLookup(value);
            },
          },
        ]
      : []),
    {
      key: 'sequence',
      title: 'Sequence',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchSequencesLookup(value);
      },
    },
  ];

  const currentTask = useMemo(() => {
    if (data && data.length && taskReplies?.taskId && taskReplies?.taskId !== '') {
      return data.filter((task) => task?.id === taskReplies?.taskId)[0];
    }
    return undefined;
  }, [data, taskReplies?.taskId]);

  const email = useMemo(() => {
    let emailType = 'work';
    if (currentTask?.sequence && currentTask?.sequence?.id) {
      emailType = currentTask?.sequence?.audienceEmailType === 'work' ? 'work' : 'personal';
    }
    if (
      props.contact &&
      props.contact?.id &&
      props.contact?.emails &&
      props.contact?.emails.length
    ) {
      let selectedEmail =
        props.contact?.emails.filter((email) => email.type == emailType)[0]?.email || undefined;
      if (!selectedEmail) {
        selectedEmail = props.contact?.emails[0].email;
      }
      return selectedEmail;
    }
    return '';
  }, [props.contact, currentTask]);

  useEffect(() => {
    if (!filters?.user) {
      fetchSent(
        {
          ...filters,
          user: {
            ...props.user,
            name: `${props.user.fname} ${props.user.lname}`,
          },
        },
        paging,
      );
    } else {
      fetchSent(filters, paging);
    }
  }, []);

  useEffect(() => {
    props.contact?.id && dispatch(fetchSequenceEnrollment(props.contact?.id));
  }, [props.contact]);

  const fetchSent = (filters, paging) => {
    props.fetchSent(filters, paging);
  };

  const refetch = () => {
    fetchSent(filters, paging);
  };

  const onFilterChange = (key, value) => {
    if (isArray(value) && !value.length) {
      return false;
    }
    const prev = JSON.stringify(filters[key]);
    if (value && typeof value !== 'undefined' && prev != JSON.stringify(value)) {
      fetchSent(
        {
          ...filters,
          [key]: value,
        },
        {
          ...paging,
          pageNo: 0,
        },
      );
    } else {
      delete filters[key];
      fetchSent(filters, paging);
    }
  };

  const handleSearch = (value) => {
    const key = '_search';
    if (value && value !== '') {
      fetchSent(
        {
          ...filters,
          [key]: value,
        },
        paging,
      );
    } else {
      delete filters[key];
      fetchSent(filters, paging);
    }
  };

  if (!data.length && !loading.sent && isEmpty(filters))
    return (
      <Empty
        title="No sent emails, yet."
        description="Create sequence today to get your sent emails!"
        buttonTitle="Create sequence"
        onClick={() => {
          history.push('/sequences');
        }}
      />
    );

  return (
    <Container disableGutters maxWidth="none">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FilterBar
            showSearch={false}
            filters={allFilters}
            appliedFilters={{ ...filters, status: { id: 'completed' } }}
            onChange={onFilterChange}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <List
            data={data}
            filters={JSON.parse(JSON.stringify(filters))}
            loading={loading?.sent || false}
            onSearch={handleSearch}
            search={filters?._search || ''}
            messageKey="messageSent"
            refetch={refetch}
            type="sent"
            selected={taskReplies?.taskId || undefined}
            {...props}
          />
        </Grid>
        <Grid item md={8} lg={8} xl={9}>
          <div
            style={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 180px)',
            }}
          >
            {props.contactLoading ? (
              <Box width="50%">
                <Skeleton showButtons={false} />
              </Box>
            ) : (
              <Profile
                contact={props.contact}
                showEdit={false}
                showAddress={false}
                showEmail={true}
                email={email}
              />
            )}
            <Spacer x={5} y={5} />
            <Messages
              taskReplies={taskReplies}
              type="sent"
              {...props}
              messagesLoading={loading?.taskReplies || false}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InboxView;
