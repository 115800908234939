import React from 'react';
import Modal from 'src/components/shared/Modal';
import Icon from 'src/components/lms/Icon';
import { ButtonBase, Stack, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';
import { useHistory } from 'react-router-dom';

function RoleplayError() {
  const history = useHistory();
  const { showRoleplayError, setShowRoleplayError } = useSessionProvider();

  const handleClose = () => {
    setShowRoleplayError(undefined);
  };

  const handleClick = () => {
    history.push(`/admin/roleplay/setting`);
    handleClose();
  };

  return (
    <Modal
      open={showRoleplayError}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#203244',
          borderRadius: '24px',
        },
      }}
      size="xs"
    >
      <Stack alignItems="center">
        <Icon name="error" color="#f44336" size={72} />
        <Spacer x={2} y={2} />
        <Typography textAlign="center" color="error" fontWeight={600}>
          {showRoleplayError?.message}
        </Typography>
        <Spacer x={0.5} y={0.5} />
        {showRoleplayError?.key === 'settings' && (
          <Typography textAlign="center" component="span">
            Please go to your{' '}
            <ButtonBase onClick={handleClick}>
              <Typography color="secondary" component="span" sx={{ textDecoration: 'underline' }}>
                User Roleplay Settings
              </Typography>
            </ButtonBase>{' '}
            to add your domain and industry.
          </Typography>
        )}
        {showRoleplayError?.key === 'error' && (
          <Typography textAlign="center" component="span">
            The AI bot is currently out of service. We have been notified and are working on the
            issue.
          </Typography>
        )}

        <Spacer x={2} y={2} />
        <Button
          size="large"
          color="secondary"
          variant="contained"
          sx={{ fontWeight: 600 }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Stack>
    </Modal>
  );
}

export default RoleplayError;
