const images = {
  auth: {
    loginBg: require('src/assets/images/LoginBg.svg').default,
    authBG: require('src/assets/images/authBG.png'),
    journeyCard: require('src/assets/images/JourneyCard.png'),
    journeySuccess: require('src/assets/images/JourneySuccess.png'),
  },
  sequence: {
    AddSeqIcon: require('src/assets/images/AddSeqIcon.svg').default,
    PublishSequenceIcon: require('src/assets/images/publishSequenceIcon.svg').default,
    CloneSeqIcon: require('src/assets/images/CloneSeqIcon.svg').default,
    SequenceEmpty: require('src/assets/images/sequenceEmpty.svg').default,
    // AddTaskFilled: require('src/assets/icons/AddTaskFilled.svg').default,
    AddTaskFilled: require('src/assets/icons/alarm.png'),
  },
  loginDashboard: {
    LMS1: require('src/assets/images/loginDashboard/LMS-1.png'),
    LMS2: require('src/assets/images/loginDashboard/LMS-2.png'),
    LMS3: require('src/assets/images/loginDashboard/LMS-3.png'),
    Outreach1: require('src/assets/images/loginDashboard/Outreach-1.png'),
    Outreach2: require('src/assets/images/loginDashboard/Outreach-2.png'),
    Outreach3: require('src/assets/images/loginDashboard/Outreach-3.png'),
  },
  app: {
    logo: require('src/assets/images/logo-SuperReach-Full.svg').default,
    emptyIcon: require('src/assets/images/emptyIcon.png'),
    logoWhite: require('src/img/logo-white.svg').default,
    logoCollapsed: require('src/img/logo-nav-collapsed.svg').default,
    gettingStartedIcon: require('src/assets/icons/gettingStartedIcon.svg').default,
    emptyBoard: require('src/assets/images/emptyBoard.svg').default,
    pipelineIcon: require('src/assets/images/pipelineIcon.svg').default,
    emailDisconnect: require('src/assets/icons/emailDisconnect.png'),
    stackOverflow: require('src/assets/icons/stack-overflow.png'),
    SRArrow: require('src/assets/icons/SRArrow.svg').default,
    gmail: require('src/assets/icons/gmail.svg').default,
    office: require('src/assets/icons/office-2.svg').default,
    emptyInbox: require('src/assets/images/emptyInbox.svg').default,
    resellerIcon: require('src/assets/icons/reseller.png'),
    terms: require('src/assets/icons/terms.png'),
  },
  nav: {
    contacts: require('src/img/nav/contactsIcon.svg').default,
    report: require('src/img/nav/reportIcon.svg').default,
    sequence: require('src/img/nav/sequenceIcon.svg').default,
    task: require('src/img/nav/taskIcon.svg').default,
    template: require('src/img/nav/templateIcon.svg').default,
    sequenceAlt: require('src/img/nav/SeqBlueIcon.svg').default,
  },
  tasks: {
    taskPhonIcon: require('src/img/icons/TaskPhonIcon.svg').default,
    taskEmailIcon: require('src/img/icons/TaskEmailIcon.svg').default,
    taskLinkedinIcon: require('src/img/icons/TaskLinkedinIcon.svg').default,
    taskGeneralIcon: require('src/img/icons/general-task.svg').default,
  },
  integrationIcons: {
    Bullhorn: require('src/assets/images/integrations/bullhorn.svg').default,
    JobAdder: require('src/img/icons/bullhorn-icon.svg').default,

    Lusha: require('src/img/icons/lusha-icon.svg').default,
  },
  integration: {
    bullhorn: require('src/img/icons/bullhorn-icon.svg').default,
    vincere: require('src/img/icons/vincere-icon.png'),
    jobAdder: require('src/img/icons/jobAdder.svg').default,
    twilio: require('src/img/icons/twilio-icon.svg').default,
    lusha: require('src/img/icons/lusha-icon.svg').default,
    reply: require('src/img/icons/reply-icon.svg').default,
    salesforce: require('src/img/icons/salesforce-icon.svg').default,
    hubspot: require('src/img/icons/hubspot-icon.svg').default,
    closeIo: require('src/img/icons/close-io-icon.svg').default,
    uplead: require('src/img/icons/uplead.svg').default,
    anymail: require('src/img/icons/anymail.svg').default,
    kendo: require('src/img/icons/kendo.svg').default,
    apollo: require('src/img/icons/apollo.svg').default,
    getanymail: require('src/img/icons/getanymail.svg').default,
    kaspr: require('src/img/icons/kaspr.png'),
  },
  onboarding: {
    whatIsSR: require('src/assets/images/onboarding/whatIsSR.png'),
    connectMailBox: require('src/assets/images/onboarding/connectMailBox.png'),
    downloadChromeExtension: require('src/assets/images/onboarding/downloadChromeExtension.jpg'),
    addContact: require('src/assets/images/onboarding/addContact.jpg'),
    createSeq: require('src/assets/images/onboarding/createSeq.jpg'),
    createTask: require('src/assets/images/onboarding/createTask.jpg'),
    supportIcon: require('src/assets/images/Support.png'),
    outReach: require('src/assets/icons/outReach.svg').default,
    quickStart: require('src/assets/icons/quickStart.svg').default,
    successTips: require('src/assets/icons/successTips.svg').default,
  },
  LMS: {
    empty: require('src/assets/icons/empty-dark.svg').default,
    cameleon: require('src/assets/images/cameleon.png'),
    sample1: require('src/assets/images/LMS/Image-sample.png'),
    image1: require('src/assets/images/LMS/home/image-1.png'),
    image2: require('src/assets/images/LMS/home/image-2.png'),
    image3: require('src/assets/images/LMS/home/image-3.png'),
    image4: require('src/assets/images/LMS/home/image-4.png'),
    image5: require('src/assets/images/LMS/home/image-5.png'),
    image6: require('src/assets/images/LMS/home/image-6.png'),
    start1: require('src/assets/images/LMS/home/start-1.png'),
    start2: require('src/assets/images/LMS/home/start-2.png'),
    start3: require('src/assets/images/LMS/home/start-3.png'),
    session1: require('src/assets/images/LMS/home/session-1.png'),
    session2: require('src/assets/images/LMS/home/session-2.png'),
    session3: require('src/assets/images/LMS/home/session-3.png'),
    session4: require('src/assets/images/LMS/home/session-4.png'),
    individualSessionBanner: require('src/assets/images/LMS/individual-session/individual-session-1.png'),
    meetExpertAvatar: require('src/assets/images/LMS/individual-session/meet-expert-avatar.png'),
    courseSec1: require('src/assets/images/LMS/CourseDetailSection/CourseImage1.png'),
    courseSec2: require('src/assets/images/LMS/CourseDetailSection/CourseImage2.png'),
    courseBannerSectionImg: require('src/assets/images/LMS/CourseDetailSection/courseBannerSection.png'),
    imagePlaceHolder: require('src/assets/icons/ImagePlaceHolder.png'),
    expertPlaceholder: require('src/assets/icons/expertPlaceholder.svg').default,
    camera: require('src/assets/icons/camera.svg').default,
    guide: require('src/assets/icons/guide.svg').default,
    robot: require('src/assets/icons/Robot.svg').default,
    squareAdd: require('src/assets/icons/SquareAdd.svg').default,
    progress: require('src/assets/icons/progress.svg').default,
    sessions: require('src/assets/images/LMS/sessions.svg').default,
    score: require('src/assets/images/LMS/score.svg').default,
    roleplay: require('src/assets/images/LMS/roleplay.svg').default,
    placeholder: require('src/assets/images/LMS/courseBGPlaceholder.png'),
  },
  prizes: {
    amazon: require('src/assets/images/LMS/prizes/amazonVoucher.svg').default,
    boosterPoints: require('src/assets/images/LMS/prizes/boosterPoints.svg').default,
    champagne: require('src/assets/images/LMS/prizes/champagne.svg').default,
    dinner: require('src/assets/images/LMS/prizes/dinner.svg').default,
    earlyFinish: require('src/assets/images/LMS/prizes/earlyFinish.svg').default,
  },
  providers: {
    rocketReach: require('src/assets/images/RocketReachLogo.svg').default,
    xing: require('src/assets/images/xing.png'),
  },
  billing: {
    mastercard: require('src/assets/images/masterCardIcon.png'),
    visa: require('src/assets/images/visaIcon.png'),
    amex: require('src/assets/images/amexIcon.png'),
    americanexpress: require('src/assets/images/amexIcon.png'),
    discover: require('src/assets/images/discoverIcon.png'),
  },
  integrations: {
    nymeria: require('src/assets/integrations/Nymeria.png'),
    contactout: require('src/assets/integrations/contactout.webp'),
    lusha: require('src/assets/integrations/Lusha.png'),
    rocketreach: require('src/assets/integrations/RocketReach.png'),
    anymail: require('src/img/icons/anymail.svg').default,
    // uplead: require('src/assets/integrations/UpLead.svg').default,
    uplead: require('src/img/icons/uplead.svg').default,
    getanymail: require('src/assets/integrations/GetAnyMail.png'),
    kaspr: require('src/img/icons/kaspr.png'),
    bullhorn: require('src/assets/integrations/Bullhorn.png'),
    hubspot: require('src/assets/integrations/Hubspot.png'),
    hunter: require('src/assets/integrations/hunter.svg').default,
    // jobadder: require('src/assets/integrations/JobAdder.svg').default,
    jobadder: require('src/img/icons/jobAdder.svg').default,
    kendo: require('src/assets/integrations/Kendo.png'),
    apollo: require('src/img/icons/apollo.svg').default,
    peoplesdatalab: require('src/assets/integrations/PeoplesDataLabs.jpeg'),
    peopledatalab: require('src/assets/integrations/PeoplesDataLabs.jpeg'),
    salesforce: require('src/assets/integrations/Salesforce.png'),
    vincere: require('src/assets/integrations/Vincere.jpg'),
    parvenu: require('src/assets/integrations/parvenu.jpeg'),
    signalhire: require('src/assets/integrations/signalhire.png'),
  },
  file: {
    zip: require('src/assets/icons/file/_zip.svg').default,
    doc: require('src/assets/icons/file/_doc.svg').default,
    docx: require('src/assets/icons/file/_doc.svg').default,
    html: require('src/assets/icons/file/_html.svg').default,
    mov: require('src/assets/icons/file/_mov.svg').default,
    mp4: require('src/assets/icons/file/_mp4.svg').default,
    pdf: require('src/assets/icons/file/_pdf.svg').default,
    png: require('src/assets/icons/file/_png.svg').default,
    xls: require('src/assets/icons/file/_xls.svg').default,
  },
  background: {
    unsubscribeBackground: require('src/img/unsubscribeBackground.png'),
  },
  roleplay: {
    roleplayBlack: require('src/assets/images/roleplay/roleplay_black.png'),
    roleplayGreen: require('src/assets/images/roleplay/roleplay_green.png'),
    insert_icon: require('src/assets/images/roleplay/insert_btn.png'),
  },
  icons: {
    playButton: require('src/assets/icons/playButton.svg').default,
  },
};

export default images;
