import React, { useEffect, useMemo, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as Zap } from 'src/assets/lms/icons/zap.svg';
import { Slider } from 'src/components/lms';
import ProgressBar from 'src/components/lms/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TickIcon } from 'src/assets/lms/icons/tick.svg';
import Item from './Item';
import darkTheme from 'src/config/darkTheme';
import { useLmsHomeProvider } from '../../utils/lmsStore';
import { putUser } from 'src/modules/auth/actions/authActions';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

function GettingStarted() {
  const { isMobile } = useMediaQuery();
  const [initialRender, setInitialRender] = useState(false);
  const [initialCompletedSlides, setInitialCompletedSlides] = useState(0);
  const { slidesDataByKey, completedSlides, fetchSlidesData } = useLmsHomeProvider();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const ref = useRef();
  ref.current = user;

  const completedIndex = useMemo(() => {
    if (ref.current && ref.current?.lmsOnboarding) {
      return Object.keys(slidesDataByKey).reduce((acc, key, index) => {
        if (ref.current.lmsOnboarding[key]) acc.push(index);
        return acc;
      }, []);
    }
    return [];
  }, [ref.current, slidesDataByKey]);

  useEffect(() => {
    if (!initialRender && Object.keys(slidesDataByKey).length) {
      setInitialCompletedSlides(
        Object.keys(user.lmsOnboarding ?? {}).filter(
          (key) => slidesDataByKey[key]?._id && user.lmsOnboarding[key],
        ).length,
      );
      setInitialRender(true);
    }
  }, [slidesDataByKey]);

  const onComplete = async (key) => {
    if (!key) return null;
    const res = await updateUser(key);
    fetchSlidesData(res.user);
  };

  const updateUser = (key) => {
    const payload = {
      id: user.id,
      data: {
        lmsOnboarding: {
          ...(ref.current?.lmsOnboarding || {}),
          [key]: true,
        },
      },
    };
    return new Promise((resolve, reject) => dispatch(putUser(payload, resolve, reject)));
  };

  if (initialCompletedSlides === Object.keys(slidesDataByKey).length) return null;
  const slides = [...Object.values(slidesDataByKey)];

  return (
    <Card>
      <CardHeader
        sx={{ background: '#082828', paddingBottom: 0 }}
        title={
          <Stack direction="row" alignItems="center" gap="20px" flexWrap="wrap">
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Zap style={{ width: 28, height: 28 }} />
              <Typography>Get Started {!isMobile && 'Here'}</Typography>
            </Stack>
          </Stack>
        }
        action={
          <Stack direction="row" alignItems="center" pr={1}>
            <Box flex={1} minWidth="120px">
              <ProgressBar
                total={Object.keys(slidesDataByKey).length}
                completedIndex={completedIndex}
              />
            </Box>
            <TickIcon
              style={{
                color:
                  Object.keys(slidesDataByKey).length === completedSlides
                    ? darkTheme.palette.secondary.main
                    : 'rgba(255, 212, 126, 0.30)',
                width: '24px',
                height: '24px',
              }}
            />
          </Stack>
        }
      />
      <CardContent sx={{ background: '#082828' }}>
        <Slider slidesToShow={1} dots={true} arrows={true}>
          {slides.map((item, index) => (
            <Item
              key={`getting-started-${index}`}
              onComplete={onComplete}
              data={{
                ...item,
                thumbnail: item?.sessionVideo?.thumbnailImageUrl,
                video: item?.sessionVideo?.videoUrl,
              }}
            />
          ))}
        </Slider>
      </CardContent>
    </Card>
  );
}

export default GettingStarted;
