import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
const _ = require('lodash');

export const fetchSingleUser = (userId) => {
  return api(`/user/${userId}`, null, 'get');
};

export const fetchUsersLookup = async (search, showEmail = false, showTotal = false, rest) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: -1,
    status_eq: 'active',
    ...(search ? { _search: search } : {}),
    ...rest,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/user${filter}`, null, 'get');
  if (!showTotal) {
    res = res.users;
    if (res && res.length) {
      res = res.map((user) => {
        return {
          ...user,
          name: `${user.fname} ${user.lname}${showEmail ? ` (${user.email})` : ''}`,
          teamId: user.team.length ? user.team[0]?.id : '',
        };
      });
    }
  }
  return res;
};

export const fetchTimezoneLookup = async (search) => {
  let res = [];
  res = await api(`/timezones`, null, 'get');
  res = res.timezones;
  if (res && res.length) {
    res = res.map((timezone) => {
      return {
        ...timezone,
        name: `${timezone.timeZone}`,
      };
    });
  }
  return res;
};

export const fetchCountryLookup = async (search) => {
  let res = [];
  res = await api(`/countries`, null, 'get');
  res = res.countries;
  if (res && res.length) {
    res = res.map((country) => {
      return {
        ...country,
        name: `${country.country}`,
      };
    });
  }
  return res;
};

export const fetchOfficeLookup = async (search) => {
  let res = [];
  res = await api(`/office`, null, 'get');
  res = res.offices;
  if (res && res.length) {
    res = res.map((office) => {
      return {
        ...office,
        name: `${office.name}`,
      };
    });
  }
  return res;
};

export const fetchTeamLookup = async (search) => {
  let res = [];
  res = await api(`/team?status_eq=active`, null, 'get');
  res = res.teams;
  if (res && res.length) {
    res = res.map((team) => {
      return {
        ...team,
        name: `${team.name}`,
      };
    });
  }
  return res;
};

export const fetchParentTeamLookup = async (search) => {
  let res = [];
  res = await api(`/team?isParent=true`, null, 'get');
  res = res.teams;
  if (res && res.length) {
    res = res.map((team) => {
      return {
        ...team,
        name: `${team.name}`,
      };
    });
  }
  return res;
};

export const getAllUsers = () => {
  return api('automation/users', null, 'get');
};

export const getCandidatesLookup = async (term = null, excludeIds = null) => {
  try {
    let query = '';
    const params = {
      term: term,
      excludeid: excludeIds,
    };
    if (!_.isEmpty(params)) {
      query = `?${convertObjectToQuerystring(params)}`;
    }
    return api(`/candidate/candidate-lookup${query}`, null, 'GET');
  } catch (err) {
    return err;
  }
};

export const getAssignToLookup = async (term = '', only = '') => {
  try {
    const params = {
      term,
      only,
    };
    let query = '';
    if (!_.isEmpty(params)) {
      query = `?${convertObjectToQuerystring(params)}`;
    }
    return api(`/dm/get-their-manager${query}`, null, 'GET');
  } catch (err) {
    return err;
  }
};

export const fetchSessionsLookup = async (search) => {
  let res = [];
  res = await api(`/lms/session?_search=${search}`, null, 'get');
  res = res.sessions;
  if (res && res.length) {
    res = res.map((session) => {
      return {
        ...session,
        name: `${session.title}`,
      };
    });
  }
  return res;
};
export const fetchCoursesLookup = async (search) => {
  let res = [];
  res = await api(`/lms/course?_search=${search}`, null, 'get');
  res = res.courses;
  if (res && res.length) {
    res = res.map((course) => {
      return {
        ...course,
        name: `${course.title}`,
      };
    });
  }
  return res;
};
export const fetchSessionsLookupFav = async () => {
  let res = [];
  res = await api(`/lms/sessions/favourite`, null, 'get');
  res = res.sessions;
  if (res && res.length) {
    res = res.map((session) => {
      return {
        ...session,
        name: `${session.title}`,
      };
    });
  }
  return res;
};
export const fetchCourseLookupFav = async () => {
  let res = [];
  res = await api(`/lms/course/favourite`, null, 'get');
  res = res.course;
  if (res && res.length) {
    res = res.map((course) => {
      return {
        ...course,
        name: `${course.title}`,
      };
    });
  }
  return res;
};
