import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';
import Button from 'src/components/shared/Button';
import Banner from 'src/components/lms/Banner';
// import SessionCard from 'src/components/lms/SessionCard';
import useStyles from './style';
import { useLmsHomeProvider } from '../../utils/lmsStore';
import RecommendedSessions from '../../components/home/RecommendedSessions';
import RecommendedCourses from '../../components/home/RecommendedCourses';
import { fetchSessionCourses, getInprogressSessions, getTagsLookup } from '../../api/LMSApis';
import { viewMoreString } from '../../utils/helper';
import { Pagination } from '@mui/material';
import Icon from 'src/components/lms/Icon';
import FilterBar from 'src/components/lms/Filterbar';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';
import SessionSkeleton from 'src/components/lms/Session/Skeleton';
import { ProgressSkeleton } from 'src/components/lms/Session/styles';
import { Spacer } from 'src/components/shared';
import Empty from 'src/components/lms/Empty';
import useRequest from 'src/components/hooks/useRequest';
import Session from 'src/components/lms/Session';
import { useHistory } from 'react-router-dom';

const headerTitle = 'In Progress';

const filters = [
  {
    key: 'sort',
    title: 'Sort',
    type: 'dropdown',
    remote: false,
    cancellable: true,
    icon: <Icon name="sort" />,
    options: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Created Date',
        value: 'createdAt',
      },
    ],
  },
  {
    key: 'tags',
    title: 'Tag',
    type: 'dropdown',
    remote: true,
    optLabel: 'name',
    optValue: 'id',
    cancellable: true,
    isPrimary: true,
    icon: <Icon name="tags" />,
    remoteMethod: getTagsLookup,
  },
];

const LMSInProgress = () => {
  const classes = useStyles();
  const { homePage, progress } = useLmsHomeProvider();

  const history = useHistory();

  const [featuredProgressSession, setFeaturedProgressSession] = React.useState({});
  const [cousesDataBySession, setCoursesDataBySessions] = React.useState({});
  const [fullFeaturedContent, showFullFeaturedContent] = React.useState(false);
  const [sessionFilters, setProgressSessionPaging] = React.useState({
    perPage: 10,
    page: 0,
  });

  const dir = sessionFilters?.sort && sessionFilters?.sort?.value == 'name' ? ':asc' : ':desc';
  const filterPayload = {
    ...(sessionFilters?.sort
      ? { _sort: sessionFilters?.sort?.value + dir ?? '' }
      : { _sort: 'createdAt:desc' }),
    ...(sessionFilters?.tags ? { tags: sessionFilters?.tags?.id ?? '' } : {}),
    _from: sessionFilters.page * sessionFilters.perPage,
    _size: sessionFilters.perPage,
    ...(sessionFilters._search ? { _search: sessionFilters._search } : {}),
    ...(sessionFilters?.tags ? { tags: sessionFilters?.tags.id } : {}),
  };
  delete filterPayload.sort;

  const [
    { progress: progressSessions = [], total: { value: totalSessions = 0 } = {} } = {},
    { loading, error },
  ] = useRequest(getInprogressSessions, filterPayload);

  React.useEffect(() => {
    if (sessionFilters.page === 0 && progressSessions.length > 0)
      setFeaturedProgressSession(progressSessions[0] ?? {});
  }, [sessionFilters.page, progressSessions]);

  const isPageLoading = loading;
  const components = homePage?.component ?? [];
  const isInProgressSessionsEmpty = totalSessions === 0;

  const handleFilter = (key, value) => {
    setProgressSessionPaging((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  return (
    <Stack className={classes.lmsInProgressPageWrapper}>
      <Stack className={classes.container}>
        <Stack className={classes.containerHeader}>
          <SlowMotionVideoRoundedIcon />
          <Typography className={classes.headerTitle}>{headerTitle}</Typography>
        </Stack>

        <FilterBar
          showSearch={true}
          filters={filters}
          value={sessionFilters}
          onChange={handleFilter}
        />

        {isInProgressSessionsEmpty && !isPageLoading ? (
          <Stack className={classes.emptyContainer}>
            <Empty
              title={'Nothing to see here.'}
              desc={'Check out some of our Courses and Sessions to get started!'}
            />
          </Stack>
        ) : (
          <React.Fragment>
            {!isPageLoading ? (
              <Session
                type="list"
                session={featuredProgressSession}
                showAssigned={false}
                showCourse={false}
                action={
                  <Stack className={classes.bannerActionButtonsWrapper}>
                    <Button
                      color={'secondary'}
                      variant="contained"
                      onClick={(e) => {
                        history.push(`/lms/session/${featuredProgressSession.id}?autoplay=video`);
                        e.stopPropagation();
                      }}
                    >
                      Continue Session
                    </Button>
                    {featuredProgressSession.showRoleplay && (
                      <Button
                        color={'secondary'}
                        variant="outlined"
                        onClick={(e) => {
                          history.push(
                            `/lms/session/${featuredProgressSession.id}?autoplay=roleplay`,
                          );
                          e.stopPropagation();
                        }}
                      >
                        AI Role-play
                      </Button>
                    )}
                  </Stack>
                }
              />
            ) : (
              <Stack direction={'row'} gap={'24px'} height={'200px'}>
                <ProgressSkeleton
                  animation="wave"
                  height={'100%'}
                  sx={{ transform: 'scale(1)' }}
                  width={'40%'}
                />
                <Stack width="60%">
                  <ProgressSkeleton animation="wave" height={20} width={'40%'} />
                  <Spacer y={2} />
                  <ProgressSkeleton animation="wave" height={14} width={'95%'} />
                  <Spacer y={1} />
                  <ProgressSkeleton animation="wave" height={14} width={'90%'} />
                  <Spacer y={1} />
                  <ProgressSkeleton animation="wave" height={14} width={'92.5%'} />
                  <Stack flex={1} />
                  <Stack direction={'row'} gap={'20px'}>
                    <ProgressSkeleton animation="wave" height={40} width={'160px'} />
                    <ProgressSkeleton animation="wave" height={40} width={'160px'} />
                  </Stack>
                </Stack>
              </Stack>
            )}

            {/* Sessions */}
            <Stack className={classes.sessionsContainer}>
              {!isPageLoading
                ? progressSessions.flatMap((session, index) => {
                    if (sessionFilters.page === 0 && index === 0) return [];
                    return <Session session={session} />;
                  })
                : Array.from({ length: 8 }).map(() => <SessionSkeleton />)}
            </Stack>

            <Stack alignItems="center" justifyContent="center">
              <Pagination
                count={Math.ceil(totalSessions / (sessionFilters?.perPage ?? 1))}
                page={sessionFilters.page + 1}
                defaultPage={1}
                perPage={sessionFilters.perPage}
                onChange={(_, page) =>
                  setProgressSessionPaging((prevState) => {
                    return {
                      ...prevState,
                      page: page - 1,
                    };
                  })
                }
              />
            </Stack>
          </React.Fragment>
        )}
      </Stack>

      {/* Other recommendations  */}
      {isInProgressSessionsEmpty &&
        !isPageLoading &&
        components.flatMap((component, index) => (
          <RenderRecommendations key={index} component={component} />
        ))}
    </Stack>
  );
};

const RenderRecommendations = ({ component }) => {
  switch (component?.name) {
    case 'recommendedCourse':
      return <RecommendedCourses title={component.label} />;

    case 'recommendedSessions':
      return <RecommendedSessions title={component.label} />;

    default:
      return [];
  }
};

const fetchAllSessionsCourseData = async (progressSessions, setCoursesDataBySessions) => {
  progressSessions.forEach(async (session) => {
    if (session.id)
      fetchSessionCourses(session.id).then((res) => {
        setCoursesDataBySessions((prevState) => {
          return { ...prevState, [session.id]: res?.courses?.[0] ?? {} };
        });
      });
  });
};

export default LMSInProgress;
