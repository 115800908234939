import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId, isNull } from 'lodash';
import { Tab, Box, Typography, Stack, useTheme } from '@mui/material';
import { Button } from '../../shared';
import AddIcon from '@mui/icons-material/Add';
import { StyledTabs, StyledTab, TabsContainer } from './styles';

const AppTabs = ({
  tabs,
  value,
  tabLabel,
  tabValue,
  onChange,
  showNewTabButton,
  buttonLabel,
  handleNewTab,
  renderTabItemActions,
  renderActiveTabItemActions,
  tabBgColor,
  count,
  orientation,
  startIcon,
  endIcon,
  customRender,
  ...props
}) => {
  const uniqueKey = uniqueId('tabs-');

  const { palette } = useTheme();

  const handleChange = (e, index) => {
    const tab = tabs[index];
    onChange(tab, index);
  };

  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => tab[tabValue] === value);
  }, [value, tabs]);

  const activeTab = useMemo(() => {
    if (value && tabValue && tabs.length) {
      return tabs.filter((tab) => tab[tabValue] === value)[0];
    }
    return {};
  }, [tabs, value, tabValue]);

  const TabButton = React.forwardRef(({ tab, index, tabProps }, ref) => (
    <>
      <>
        {renderActiveTabItemActions && typeof renderActiveTabItemActions === 'function' ? (
          <>
            {index === activeTabIndex ? (
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                justifyContent="center"
                sx={{
                  ...tabProps,
                  height: '46px',
                  padding: '0px 10px',
                  '& svg': { fill: '#636363' },
                }}
                flexDirection="row"
                ref={ref}
              >
                <Stack direction="row" sx={{ '& svg': { fill: '#1976D2' } }}>
                  {tab?.icon}
                  <Typography variant="body2" sx={{ fontWeight: '500', ...tabProps }}>
                    {tab[tabLabel]}
                  </Typography>
                  <Box ml={1} mr={1}>
                    {renderActiveTabItemActions(tab)}
                  </Box>
                </Stack>
              </Box>
            ) : (
              <>
                <Tab
                  label={tab[tabLabel]}
                  onClick={(e) => handleChange(e, index)}
                  display="flex"
                  height="100%"
                  ref={ref}
                  sx={{
                    ...tabProps,
                    opacity: 1,
                    padding: '0px 10px',
                    minWidth: '54px',
                    minHeight: '24px',
                    textTransform: 'none',
                    color: '#646464',
                    height: '46px',
                  }}
                />
              </>
            )}
          </>
        ) : (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            onClick={(e) => handleChange(e, index)}
            sx={{
              ...props,
              height: '46px',
              marginRight: '8px',
              '& svg': { fill: index === activeTabIndex ? '#1976D2' : '#636363' },
            }}
            ref={ref}
          >
            {tab?.icon}
            <Tab
              label={tab[tabLabel]}
              sx={{
                padding: '0px 10px',
                minWidth: '54px',
                textTransform: 'none',
                minHeight: '24px',
                ...(index === activeTabIndex
                  ? {
                      color: palette.secondary.main,
                      opacity: 1,
                    }
                  : null),
              }}
            />
          </Box>
        )}
      </>
    </>
  ));

  return (
    <Box
      display="flex"
      justifyContent={props.justifyContent}
      alignItems="center"
      sx={{ borderBottom: `1px solid ${palette.divider}` }}
    >
      <StyledTabs
        indicatorColor="primary"
        onChange={handleChange}
        textColor="secondary"
        value={activeTabIndex}
        {...props}
        orientation={orientation}
        TabIndicatorProps={{
          ...(activeTab && activeTab?.tabColor && activeTab?.tabColor !== '#ffffff'
            ? {
                style: { background: activeTab.tabColor },
              }
            : {
                style: { background: palette.secondary.main },
              }),
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <StyledTab
              key={`${uniqueKey}-${index}`}
              label={tab[tabLabel]}
              color={tab?.tabBgColor || false}
              component={React.forwardRef((props, ref) => {
                const tabProps = {
                  ...(tabBgColor &&
                    typeof tabBgColor !== 'undefined' && {
                      color: tab[tabBgColor] === '#ffffff' ? '#646464' : tab[tabBgColor],
                      opacity: 1,
                    }),
                };

                return (
                  <TabButton tab={tab} index={index} tabProps={tabProps} {...props} ref={ref} />
                );
              })}
            />
          );
        })}
      </StyledTabs>
      {showNewTabButton ? (
        <Box display="flex" marginLeft={'auto'}>
          <Button
            sx={{ marginLeft: 2 }}
            startIcon={startIcon}
            onClick={handleNewTab}
            endIcon={endIcon}
            color="secondary"
          >
            {buttonLabel}
          </Button>
        </Box>
      ) : null}
      {customRender && <Box display="flex">{customRender()}</Box>}
    </Box>
  );
};

AppTabs.propTypes = {
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  tabLabel: PropTypes.string,
  tabValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showNewTabButton: PropTypes.bool,
  customRender: PropTypes.any,
  buttonLabel: PropTypes.string,
  handleNewTab: PropTypes.func,
  renderTabItemActions: PropTypes.func,
  renderActiveTabItemActions: PropTypes.any,
  tabBgColor: PropTypes.any,
  count: PropTypes.any,
  orientation: PropTypes.string,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  justifyContent: PropTypes.string,
};

AppTabs.defaultProps = {
  tabs: [],
  onChange: () => {},
  tabLabel: 'name',
  tabValue: 'id',
  value: undefined,
  showNewTabButton: false,
  buttonLabel: '',
  handleNewTab: () => {},
  renderTabItemActions: () => {},
  renderActiveTabItemActions: undefined,
  tabBgColor: undefined,
  count: 0,
  orientation: 'horizontal',
  startIcon: <AddIcon />,
  endIcon: null,
  justifyContent: 'flex-start',
  customRender: undefined,
};

export default AppTabs;
