import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Spacer, Button } from 'src/components/shared';
import { Card, CardContent, Stack, Typography, Box } from '@mui/material';
import images from 'src/config/images';
import styled from 'styled-components';
import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';
import { useSelector } from 'react-redux';
import { ConfirmDialog } from 'src/components/App';

function AiCTA({ session, score }) {
  const tenant = useSelector((state) => state.app.tenant);
  const { openRoleplay } = useSessionProvider();
  const [roleplayMinModal, setRoleplayMinModal] = useState(false);
  const appHistory = useHistory();

  if (!session.showRoleplay) return null;
  if (score?.roleplayScore && score?.roleplayScore.length) return null;

  return (
    <Box mt={3}>
      <Card>
        <CardContent sx={{ padding: '24px' }}>
          <Spacer x={1} y={1} />
          <Typography color="textPrimary" textAlign="center" variant="body2">
            Don’t be an average Joe. Practice your {session.title} with one of our Ai Avatars.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            mt={3}
            mb={1}
          >
            <Camelon src={images.LMS.cameleon} />
            <Button
              variant="contained"
              sx={{
                backgroundColor: tenant?.roleplayMinutes > 0 ? '#FFD47E' : 'rgba(18, 81, 81, 1)',
              }}
              size="large"
              onClick={() => {
                if (tenant?.roleplayMinutes && tenant?.roleplayMinutes > 0) openRoleplay();
                else setRoleplayMinModal(true);
              }}
            >
              Role-play now
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <ConfirmDialog
        title="Role-play minutes exhausted"
        open={roleplayMinModal}
        onClose={() => {
          setRoleplayMinModal(false);
        }}
        onConfirm={() => {
          setRoleplayMinModal(false);
          appHistory.push(`/admin/billing/subscriptions`);
        }}
        buttonTitle="Purchase now"
        cancelButtonColor="secondary"
        cancelButtonVariant="outlined"
        confirmButtonVariant="contained"
      >
        Your role-play minutes are exhausted! Click below to purchase more role-play minutes.
      </ConfirmDialog>
    </Box>
  );
}

export default AiCTA;

const Camelon = styled.img`
  max-width: 105px;
  width: 100%;
`;
