import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      '& button': {
        marginRight: 0,
      },
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  centeredCell: {
    textAlign: 'center',
    '& div:nth-child(2) ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
}));
