import { all, fork, put, call, select, takeLatest } from 'redux-saga/effects';
import * as authTypes from '../actions/authTypes';
import * as authApis from '../api/authApis';
import * as authActions from '../actions/authActions';
import {
  setAppLoading,
  fetchGlobals,
  fetchTaskCount,
  fetchTenantConfig,
  setLoginStatus,
  resetStore,
} from 'src/modules/app/actions/appActions';
import { fetchBoards } from 'src/modules/app/actions/boardActions';
import toast from 'src/utils/toast';
import { push, replace } from 'connected-react-router';
import storage from 'src/utils/storageUtils';
import { authPages, isPathMatched, standAlonePages } from 'src/config';
import { Mixpanel } from 'src/utils/mixpanel';

import ReactGA from 'react-ga4';

export const getRouter = (state) => state.router;
export const getCurrentUser = (state) => state.auth.user;

function* loginUser({ data, resolve, reject }) {
  try {
    const res = yield call(authApis.loginUser, data);
    const { user } = res;
    if (!user?.id) {
      toast.error('You have entered an invalid email or password', 'tc');
      reject('You have entered an invalid email or password');
    }
    const isNonAdmin = user?.acl?.accessLevel?.toLowerCase() !== 'admin';
    const isNotActive = ['inactive', 'paused', 'cancelled'].includes(user?.subscriptionStatus);
    const isSubscriptionFailed = ['failure', 'needs_attention'].includes(user?.subscriptionStatus);

    const hasNotAccess =
      !user?.lmsmonthly && !user?.lmsannual && !user?.outreachAnnual && !user?.outreachMonthly;

    if (isNonAdmin && (isNotActive || hasNotAccess || isSubscriptionFailed)) {
      yield put(
        authActions.userLoginError(
          isSubscriptionFailed
            ? 'There is an issue with your company subscription. Please speak to your administrator.'
            : 'We can’t find an active subscription. Please speak to your administrator.',
        ),
      );
    } else {
      storage.set('TOKEN', res.jwt);
      const tenantHash = res.tenants[0];
      storage.set('TENANT_HASH', tenantHash);
      yield put(authActions.restoreSession());

      // window.gtag('event', 'login', {
      //   event_category: 'access',
      //   event_label: 'login',
      // });
      ReactGA.event({
        category: 'access',
        action: 'login',
        label: 'login',
      });

      if (
        (res?.user?.lmsmonthly || res?.user?.lmsannual) &&
        (res?.user?.outreachAnnual || res?.user?.outreachMonthly)
      )
        yield put(authActions.showWelcomeModal(true));
      toast.success('Login successful!', 'tc');
    }
    resolve(res);
  } catch (error) {
    toast.error(error?.error?.message || 'You have entered an invalid email or password', 'tc');
    reject(error);
  }
}

function* restoreSession({ newUser, redirect = true }) {
  const router = yield select(getRouter);
  const currentRoute = router.location.pathname;
  try {
    const token = yield call(storage.get, 'TOKEN');
    const tenantHash = yield call(storage.get, 'TENANT_HASH');
    if (token && tenantHash && token !== '' && tenantHash !== '') {
      if (redirect === true) {
        yield put(setAppLoading(true));
      }
      const profile = yield call(authApis.getProfile);
      window.DD_RUM.setUser({
        id: profile.user.id,
        name: `${profile.user.fname} ${profile.user.lname}`,
        email: profile.user.email,
      });

      yield put(authActions.setAuth(profile.user));
      yield put(setLoginStatus(true));
      yield put(fetchGlobals());
      // yield put(fetchTaskCount());
      yield put(fetchTenantConfig());
      yield put(fetchBoards('lg'));
      if (redirect === true) {
        yield put(setAppLoading(false));
      }
      if (redirect === true) {
        if (router?.location?.query?.refferal) {
          yield put(replace(router?.location?.query?.refferal));
        } else if (currentRoute === '/auth/forgot-password') {
          yield put(replace('/profile/security'));
        } else if (authPages.includes(currentRoute)) {
          const hasOutreachAccess =
            profile.user?.outreachAnnual === true || profile.user?.outreachMonthly === true;
          if (
            (profile.user?.lmsannual === true || profile.user?.lmsmonthly === true) &&
            !hasOutreachAccess
          ) {
            yield put(replace('/lms'));
          } else {
            if (profile?.user?.onboardingCompleted === true) {
              if (newUser === true) {
                yield put(replace('/onboarding'));
              } else {
                yield put(replace('/tasks'));
              }
            } else {
              yield put(replace('/onboarding'));
            }
          }
        } else {
          if (!profile?.user?.onboardingCompleted && false) {
            yield put(replace('/onboarding'));
          }
        }
      }
    } else {
      if (
        !authPages.includes(currentRoute) &&
        !standAlonePages.some((path) => isPathMatched(currentRoute, path))
      ) {
        storage.del('TOKEN');
        storage.del('TENANT_HASH');
        yield put(
          replace(
            currentRoute && currentRoute != '/'
              ? `/auth/login?refferal=${currentRoute}`
              : '/auth/login',
          ),
        );
      }
      yield put(setAppLoading(false));
    }
  } catch (error) {
    if (!authPages.includes(currentRoute)) {
      yield put(replace('/auth/login'));
    }
    yield put(setAppLoading(false));
    storage.del('TOKEN');
    storage.del('TENANT_HASH');
  }
}

function* logoutUser() {
  try {
    storage.del('TOKEN');
    storage.del('TENANT_HASH');
    yield put(resetStore());
    yield put(replace('/auth/login'));
    localStorage.clear();
  } catch (error) {}
}

function* forgotPassword({ payload, resolve, reject }) {
  try {
    const res = yield call(authApis.forgotPassword, payload);
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* resetPassword({ payload, resolve, reject }) {
  try {
    const token = yield call(storage.get, 'TOKEN');
    const res = yield call(authApis.resetPassword, payload, token);
    toast.success('Password Updated!', 'tc');
    resolve(res);
  } catch (error) {
    reject(error);
  }
}
function* updateUser({ payload, resolve = () => {}, reject = () => {} }) {
  try {
    const curUser = yield select(getCurrentUser);
    const userData = yield call(authApis.updateUser, payload);
    if (curUser.id === payload.id) {
      yield put(authActions.setCurrentUser({ userData }));
      toast.success('Profile Updated!', 'tc');
    } else {
      toast.success('User Updated!', 'tc');
    }
    resolve(userData);
  } catch (error) {
    const genericErrorMsg = 'Profile update failed!';
    const errorMsg = error?.error?.message ? error.error.message : genericErrorMsg;
    toast.error(errorMsg, 'tc');
    reject(error);
  }
}
function* getUserById({ id, resolve, reject }) {
  try {
    const userData = yield call(authApis.getUserById, id);
    yield put(authActions.setUser({ userData }));
    yield put(setLoginStatus(false));
    resolve(userData);
  } catch (error) {
    reject(error);
  }
}

function* setNlToken({ code, userid, resolve, reject }) {
  try {
    const res = yield call(authApis.setNlToken, code, userid);
    const userData = { nlAccessToken: res.upUser[0].nlAccessToken, status: res.upUser[0].status };
    yield put(authActions.setEmailNLToken({ userData }));
    resolve(res);
    yield put(replace('/profile/email'));
    toast.success('Account Connected!', 'tc');
  } catch (error) {
    reject(error);
  }
}
function* disconnectIntegration({ id, status, resolve, reject }) {
  try {
    const res = yield call(authApis.disconnectIntegration, id, status);
    const userData = { nlAccessToken: res.upUser[0].nlAccessToken, status: res.upUser[0].status };
    yield put(authActions.setEmailNLToken({ userData }));
    resolve(res);
    toast.success('Account Disconnected!', 'tc');
  } catch (error) {
    reject(error);
  }
}
function* uploadImage({ file, resolve, reject }) {
  try {
    const imageData = yield call(authApis.uploadImage, file);
    yield put(authActions.setUploadImage({ imageData }));
    resolve(imageData);
    toast.success('Image Uploaded', 'tc');
  } catch (error) {
    reject(error);
  }
}

function* registerUser({ data, resolve, reject }) {
  try {
    const payload = JSON.parse(JSON.stringify(data));
    delete payload.terms;
    const res = yield call(authApis.registerUser, payload);
    yield put(replace(`/auth/verification?email=${payload.email}`));
    resolve(res);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* registerSetPassword({ uid, data, resolve, reject }) {
  try {
    const { redirect = false, ...dataToSave } = data;
    const res = yield call(authApis.registerUserSetPassword, uid, dataToSave);
    storage.set('TOKEN', res.jwt);
    const tenantHash = res.tenants[0];
    storage.set('TENANT_HASH', tenantHash);
    yield put(authActions.restoreSession(true, redirect));
    // yield put(authActions.showWelcomeModal(true, true));
    const user = res.user;
    yield call(Mixpanel.identify, user.id);
    yield call(Mixpanel.track, 'User Signed Up');
    yield call(Mixpanel.people.set, {
      $first_name: user.fname,
      $last_name: user.lname,
      // $name: `${user.fname} ${user.lname}`,
      $email: user.email,
      $tenant: user.tenants.length ? user.tenants[0]?.name : '',
    });
    toast.success('Registration successful!');
    resolve(true);
  } catch (error) {
    toast.error('Error occurred. Please try again!');
    reject(false);
  }
}

export function* watchSagas() {
  yield takeLatest(authTypes.LOGIN, loginUser);
  yield takeLatest(authTypes.REGISTER, registerUser);
  yield takeLatest(authTypes.REGISTER_SET_PASSWORD, registerSetPassword);
  yield takeLatest(authTypes.RESTORE_SESSION, restoreSession);
  yield takeLatest(authTypes.LOGOUT, logoutUser);
  yield takeLatest(authTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(authTypes.RESET_PASSWORD, resetPassword);
  yield takeLatest(authTypes.UPDATE_CURRENT_USER, updateUser);
  yield takeLatest(authTypes.GET_CURRENT_USER, getUserById);
  yield takeLatest(authTypes.SET_NL_TOKEN, setNlToken);
  yield takeLatest(authTypes.UPLOAD_IMAGE, uploadImage);
  yield takeLatest(authTypes.DISCONNECT_EMAIL_INTEGRATION, disconnectIntegration);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
