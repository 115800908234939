import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

import { FilterBar, Tabs } from 'src/components/App';
import { useDashboardStore } from '../../../utils/dashboardStore';
import SequenceGrid from './SequenceGrid';
import UserGrid from './UserGrid';
import TeamGrid from './TeamGrid';
import Cards from './Cards';
import { Spacer } from 'src/components/shared';
import { fetchTeamsLookup } from '../../../../admin/api/adminApi';
import { fetchSequencesLookup } from '../../../../sequence/api/sequenceApi';
import { fetchUsersLookup } from '../../../../users/api/usersApi';
import LineChartReport from './LineChart';

function Sequences() {
  const { filters, setFilters, setChartType } = useDashboardStore();
  const [currentTab, setCurrentTab] = useState('sequences');

  const filtersArr = [
    {
      key: 'enrolledby',
      label: 'Enrolled',
      title: 'Enrolled by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: false,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'teams',
      label: 'Team',
      title: 'All Teams',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      remoteMethod: async (value) => {
        return fetchTeamsLookup(value);
      },
    },
    {
      key: 'sequences',
      label: 'Sequence',
      title: 'All Sequences',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: false,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      remoteMethod: async (value) => {
        return fetchSequencesLookup(value);
      },
    },
    {
      key: 'createdAt',
      title: 'Filter By Date',
      custom: true,
      isCloseIcon: false,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'last7days', name: 'Last 7 days' },
        { id: 'last14days', name: 'Last 14 days' },
        { id: 'last30Days', name: 'Last 30 days' },
        { id: 'last90Days', name: 'Last 90 days' },
        { id: 'last180Days', name: 'Last 180 days' },
        { id: 'last365Days', name: 'Last 365 days' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'thisQuarter', name: 'This quarter' },
        { id: 'thisYear', name: 'This year' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'lastQuarter', name: 'Last quarter' },
        { id: 'lastYear', name: 'Last year' },
      ],
      isPrimary: true,
    },
  ];

  const handleTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const handleFilterChange = (key, value) => {
    const daily = [
      'today',
      'yesterday',
      'last7days',
      'last14days',
      'last30Days',
      'thisWeek',
      'thisMonth',
      'lastWeek',
      'lastMonth',
    ];
    const weekly = ['last90Days', 'last180Days', 'thisQuarter', 'lastQuarter'];
    const monthly = ['last365Days', 'thisYear', 'lastYear'];
    if (value && typeof value !== 'undefined') {
      if (daily.includes(value.id)) {
        setChartType('daily');
      } else if (weekly.includes(value.id)) {
        setChartType('weekly');
      } else if (monthly.includes(value.id)) {
        setChartType('monthly');
      } else {
        setChartType('weekly');
      }
      setFilters({
        ...filters,
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };

  return (
    <>
      <FilterBar
        filters={filtersArr}
        appliedFilters={filters}
        onChange={handleFilterChange}
        withClearButton
      />
      <Spacer x={1} y={1} />
      <Cards />
      <Spacer x={2} y={3} />
      <LineChartReport />
      <Spacer x={2} y={2} />
      <Box mb={2}>
        <Tabs
          tabs={[
            {
              id: 'sequences',
              name: 'Sequences',
              icon: <SendIcon />,
            },
            {
              id: 'users',
              name: 'Enrolled by',
              icon: <PersonIcon />,
            },
            {
              id: 'teams',
              name: 'Teams',
              icon: <GroupIcon />,
            },
          ]}
          value={currentTab}
          onChange={handleTabChange}
          sx={{ zIndex: 100 }}
        />
      </Box>
      {currentTab === 'sequences' && <SequenceGrid />}
      {currentTab === 'users' && <UserGrid />}
      {currentTab === 'teams' && <TeamGrid />}
    </>
  );
}

export default Sequences;
