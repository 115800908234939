export const scrollToRef = (ref) => {
  if (ref && ref?.current) {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  }
};

export const nth = (d, concat = true) => {
  let abbr = '';

  const reminder = d % 10;
  switch (true) {
    case d > 3 && d < 21:
      abbr = 'th';
      break;

    case d == 1:
    case reminder == 1:
      abbr = 'st';
      break;

    case d == 2:
    case reminder == 2:
      abbr = 'nd';
      break;
  }
  if (concat) return `${d}${abbr}`;
  return abbr;
};

export const stripTrailingSlash = function (str) {
  return str.replace(/\/\/*/g, '/').replace(/\/+$/, '');
};

export const capitalizeFirstLetter = (string) => {
  if (string && string !== '') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const lowerFirstLetter = (string) => {
  if (string && string !== '') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const getContactStatusLabel = (status) => {
  switch (status) {
    case 'not_intrested':
      return 'Not Interested';

    case 'intrested':
      return 'Not Interested';

    case 'new':
      return 'New';

    case 'hold':
      return 'Hold';

    case '':
    default:
      return 'In Progress';
  }
};

export const getSequenceStatusLabel = (status) => {
  switch (status) {
    case 'inProgress':
      return 'In Progress';
    case 'interested':
      return 'Interested';
    case 'notInterested':
      return 'Not Interested';
    case 'hold':
      return 'Hold';
    case 'completed':
      return 'Completed';
    case 'failed':
      return 'Failed';
    case 'bounced':
      return 'Bounced';
    case 'error':
      return 'Error';
    case 'draft':
      return 'Draft';
    case 'paused':
      return 'Paused';
    case 'replied':
      return 'Replied';
    case 'unsubscribed':
      return 'Unsubscribed';
    default:
      return '';
  }
};

export const getSequenceStatusColor = (status) => {
  switch (status) {
    case 'interested':
      return { color: '#ffffff', bgColor: 'rgb(76, 175, 80)' };

    case 'notInterested':
      return { color: '#ffffff', bgColor: 'rgb(71, 137, 194)' };
      break;

    case 'inProgress':
      return { color: '#ffffff', bgColor: 'rgb(71, 73, 214);' };
      break;

    case 'hold':
      return { color: '#ffffff', bgColor: 'rgb(158, 158, 158)' };

    case 'completed':
      return { color: '#ffffff', bgColor: 'rgb(76, 175, 80)' };

    case 'failed':
      return { color: '#ffffff', bgColor: 'rgb(139,0,0)' };

    default:
      return { color: '#333333', bgColor: 'rgba(0, 0, 0, 0.08)' };
      break;
  }
};

export const getContactStatusColor = (status) => {
  switch (status) {
    case 'not_intrested':
      return { color: '#ffffff', bgColor: '#EF5350' };
      break;

    case '#66BB6A':
      return { color: '#ffffff', bgColor: 'Not Interested' };
      break;

    case 'new':
      return { color: 'rgba(71, 137, 194, 1)', bgColor: 'rgba(71, 137, 194, 0.12)' };

    default:
      return { color: '#333333', bgColor: 'rgba(0, 0, 0, 0.08)' };
      break;
  }
};

export const getParentNode = (array, link, parent) => {
  return array.some(
    (o) => o.link === link || (o.child && (parent = getParentNode(o.child, link, o)) !== null),
  )
    ? parent
    : null;
};

export const parseUserDisplayName = (str = '') => {
  return (str || '').toLowerCase().replace(/\b(\w)/g, (name) => name.toUpperCase());
};

export const convertObjectToQuerystring = (obj) => {
  const qs = Object.keys(obj)
    .filter((key) => obj[key] !== '')
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
  return qs;
};

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
  } catch (err) {}

  document.body.removeChild(textArea);
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {},
  );
}

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' (' + sizes[i] + ')';
};
