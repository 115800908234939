import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Divider, Card, CardContent, Chip } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { Paging } from 'src/components/App';

import { MainGrid, Left, Right, PaperWrap, PlusIcon } from './styles';
import { fetchBoardTemplates } from '../../../actions/boardActions';
import Categories from './Categories';
import Item from './Item';
import Skeleton from './Skeleton';
import RefreshIcon from '@mui/icons-material/Refresh';

const Templates = ({ templateCategories, loading, ...props }) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.boards.templates);
  const { data, filters, paging } = templates;

  useEffect(() => {
    if (!data.length) {
      fetchData(filters, paging);
    }
  }, []);

  const fetchData = (filters, paging) => {
    dispatch(fetchBoardTemplates(filters, paging));
  };

  const onFilter = (newFilter) => {
    dispatch(fetchBoardTemplates(filters, paging));
  };

  const handlePageChange = (page) => {
    dispatch(fetchBoardTemplates(filters, { ...paging, pageNo: page }));
  };

  return (
    <Box>
      <MainGrid>
        <Left>
          <Categories
            title="Browse by category"
            onChange={onFilter}
            categories={templateCategories}
          />
        </Left>

        <Right>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h2">Recommended for you</Typography>
            <Chip
              icon={<RefreshIcon />}
              label="Refresh"
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => fetchData(filters, { ...paging, pageNo: 0 })}
            />
          </Box>
          <Spacer x={2} y={2} />

          {loading.templates === true ? (
            <Skeleton />
          ) : (
            <Grid container spacing={2}>
              {paging?.pageNo === 0 ? (
                <>
                  <Grid item xs={4}>
                    <Item
                      data={{
                        name: 'Widget Board',
                        description: 'Create your own widget dashboard from a blank canvas',
                      }}
                      type="addNew"
                      onClick={props.handleWidgetsList}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Item
                      data={{
                        name: 'Pipeline Board',
                        description: 'Create your own pipeline dashboard from a blank canvas',
                      }}
                      type="addNew"
                      onClick={() => {
                        props.handleSwimLaneView();
                      }}
                    />
                  </Grid> */}
                </>
              ) : null}

              {data.length ? (
                <>
                  {data.map((item, index) => {
                    return (
                      <Grid item xs={4} key={`board-template-${index}`}>
                        <Item
                          data={item}
                          type="template"
                          onClick={() => {
                            props.handleBoardTemplateClick(data);
                          }}
                        />
                      </Grid>
                    );
                  })}

                  <Paging
                    count={paging?.count}
                    perPage={paging?.perPage}
                    page={paging?.pageNo}
                    onChange={handlePageChange}
                  />
                </>
              ) : null}
            </Grid>
          )}
        </Right>
      </MainGrid>
    </Box>
  );
};

export default Templates;
